import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import apiURL from "../../api/api";

const ConfiguracionUsuario = () => {
  const adminId = 1;

  const [adminInfo, setAdminInfo] = useState({
    nombre: '',
    apellidos: '',
    nombre_usuario: '',
  });

  const [passwordActual, setPasswordActual] = useState('');
  const [nuevaPassword, setNuevaPassword] = useState('');
  const [confirmarPassword, setConfirmarPassword] = useState('');

  useEffect(() => {
    const fetchAdminInfo = async () => {
      try {
        const response = await fetch(`${apiURL}/api/admin/${adminId}`);
        if (!response.ok) throw new Error('Error al obtener la información del administrador');
        
        const data = await response.json();
        setAdminInfo(data);
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'No se pudo obtener la información del administrador.',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      }
    };

    fetchAdminInfo();
  }, [adminId]);

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    if (!passwordActual || !nuevaPassword || !confirmarPassword) {
      Swal.fire({
        title: 'Campos incompletos',
        text: 'Por favor, completa todos los campos de contraseña.',
        icon: 'warning',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    try {
      const response = await fetch(`${apiURL}/api/admin/cambiar-contrasena/${adminId}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ passwordActual, nuevaPassword, confirmarPassword }),
      });

      if (!response.ok) throw new Error('Error en la solicitud al servidor');

      const result = await response.json();
      Swal.fire({
        title: '¡Contraseña actualizada!',
        text: 'La contraseña se cambió correctamente.',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
      setPasswordActual('');
      setNuevaPassword('');
      setConfirmarPassword('');
    } catch (error) {
      Swal.fire({
        title: 'Error de servidor',
        text: error.message || 'Ocurrió un error en el servidor. Por favor, intenta nuevamente.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const handleInfoChange = async () => {
    try {
      const response = await fetch(`${apiURL}/api/admin/${adminId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(adminInfo),
      });

      if (!response.ok) throw new Error('Error en la solicitud al servidor');

      const result = await response.json();
      Swal.fire({
        title: '¡Datos actualizados!',
        text: 'La información se actualizó correctamente.',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
    } catch (error) {
      Swal.fire({
        title: 'Error de servidor',
        text: error.message || 'Ocurrió un error en el servidor. Por favor, intenta nuevamente.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-8">
      <h2 className="text-xl font-bold text-white bg-blue-500 rounded-t-lg p-4 text-center">
        Información personal
      </h2>

      {/* Formulario de Información Personal */}
      <form onSubmit={(e) => { e.preventDefault(); handleInfoChange(); }} className="grid grid-cols-1 md:grid-cols-2 gap-6 p-6 border-b">
        <div>
          <label className="block font-medium mb-1">Nombre:</label>
          <input
            type="text"
            value={adminInfo.nombre}
            onChange={(e) => setAdminInfo({ ...adminInfo, nombre: e.target.value })}
            className="w-full p-3 border rounded"
          />
        </div>
        <div>
          <label className="block font-medium mb-1">Apellidos:</label>
          <input
            type="text"
            value={adminInfo.apellidos}
            onChange={(e) => setAdminInfo({ ...adminInfo, apellidos: e.target.value })}
            className="w-full p-3 border rounded"
          />
        </div>
        <div className="md:col-span-2">
          <label className="block font-medium mb-1">Nombre de Usuario:</label>
          <input
            type="text"
            value={adminInfo.nombre_usuario}
            onChange={(e) => setAdminInfo({ ...adminInfo, nombre_usuario: e.target.value })}
            autoComplete="username"
            className="w-full p-3 border rounded"
          />
        </div>
        <div className="md:col-span-2 flex justify-end">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded">
            Guardar Información
          </button>
        </div>
      </form>

      {/* Formulario de Cambio de Contraseña */}
      <div className="p-6 border-b">
        <h3 className="text-md font-semibold mb-4">CAMBIAR CONTRASEÑA</h3>
        <form onSubmit={handlePasswordChange} className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <label className="block font-medium mb-1">Contraseña Antigua:</label>
            <input
              type="password"
              value={passwordActual}
              onChange={(e) => setPasswordActual(e.target.value)}
              className="w-full p-3 border rounded"
              required
              autoComplete="current-password"
            />
          </div>
          <div>
            <label className="block font-medium mb-1">Nueva Contraseña:</label>
            <input
              type="password"
              value={nuevaPassword}
              onChange={(e) => setNuevaPassword(e.target.value)}
              className="w-full p-3 border rounded"
              required
              autoComplete="new-password"
            />
          </div>
          <div>
            <label className="block font-medium mb-1">Confirmar Contraseña Nueva:</label>
            <input
              type="password"
              value={confirmarPassword}
              onChange={(e) => setConfirmarPassword(e.target.value)}
              className="w-full p-3 border rounded"
              required
              autoComplete="new-password"
            />
          </div>
          <div className="md:col-span-3 flex justify-end">
            <button
              type="submit"
              className="bg-green-500 hover:bg-green-600 text-white py-2 px-6 rounded">
              Cambiar Contraseña
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfiguracionUsuario;
