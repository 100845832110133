import React, { useEffect, useState, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import apiURL from "../../api/api";

function BienvenidoCliente() {
  const { clienteInfo } = useAuth();
  const [estadoPagos, setEstadoPagos] = useState([]);
  const [pagosRecientes, setPagosRecientes] = useState([]);
  const [añoActual, setAñoActual] = useState(new Date().getFullYear());
  const [cargandoInicial, setCargandoInicial] = useState(true);
  const [error, setError] = useState(null);

  const meses = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];

  const obtenerPagos = useCallback(async (year) => {
    if (!clienteInfo || !clienteInfo.id_cliente) return;
  
    if (year === undefined) {
      year = añoActual; // Usa el año actual si no se pasa uno
    }
  
    setError(null);
  
    try {
      const response = await axios.get(
        `${apiURL}/api/pagos/${clienteInfo.id_cliente}?year=${year}`
      );
  
      if (response.data && response.data.pagos) {
        // Filtrar pagos válidos (excluyendo montos 0 o pagos "pendientes")
        const pagosFiltrados = response.data.pagos.filter((pago) => {
          return (
            Number(pago.monto) > 0 && // Excluir pagos con monto 0
            pago.estado_pago === 'pagado' // Excluir pagos pendientes u otros estados
          );
        });
  
        // Ordenar los pagos filtrados
        const pagosOrdenados = pagosFiltrados.sort(
          (a, b) => new Date(b.fecha_pago) - new Date(a.fecha_pago)
        );
  
        setEstadoPagos(pagosFiltrados);
        setPagosRecientes(pagosOrdenados.slice(0, 2));
      } else {
        setEstadoPagos([]);
        setPagosRecientes([]);
      }
    } catch (err) {
      console.error("Error al obtener los pagos:", err);
      setError("No se pudieron cargar los datos. Intenta nuevamente.");
    }
  }, [clienteInfo, añoActual]);
  
  useEffect(() => {
    const cargarPagosIniciales = async () => {
      await obtenerPagos();
      setCargandoInicial(false); // Finaliza la carga inicial
    };
    cargarPagosIniciales();
  }, [obtenerPagos]);

  const cambiarAño = (nuevoAño) => {
    setAñoActual(nuevoAño);
    obtenerPagos(nuevoAño); // Actualiza los datos sin mostrar "cargando"
  };

  const obtenerClaseEstado = (estadoPago) => {
    switch (estadoPago) {
      case "pagado":
        return "bg-green-500 text-white";
      case "pendiente":
        return "bg-orange-500 text-white";
      case "futuro":
        return "border border-black bg-white text-black";
      case "no_aplica":
        return "bg-gray-300 text-white";
      default:
        return "bg-gray-300 text-white";
    }
  };

  const obtenerEstadoMes = (mes) => {
    const mesActual = new Date().getMonth() + 1;
    const añoHoy = new Date().getFullYear();
    let estadoMes = "futuro";

    if (añoActual < añoHoy) {
      return "no_aplica";
    }

    for (let pago of estadoPagos) {
      const { mes_inicio, mes_fin, año_inicio, estado_pago, es_ausencia, es_reactivacion } = pago;

      if (
        estado_pago === "pagado" &&
        año_inicio === añoActual &&
        mes >= mes_inicio &&
        mes <= (mes_fin || mes_inicio)
      ) {
        estadoMes = "pagado";
      }

      if (es_reactivacion && año_inicio === añoActual) {
        if (mes < mes_inicio) {
          estadoMes = "no_aplica";
        } else if (mes === mes_inicio) {
          estadoMes = "pagado";
        }
      }

      if (es_ausencia && año_inicio === añoActual && mes >= mes_inicio) {
        estadoMes = "no_aplica";
      }
    }

    if (añoActual === añoHoy && mes < mesActual && estadoMes === "futuro") {
      estadoMes = "pendiente";
    }

    return estadoMes;
  };

  const downloadFactura = (id_pago) => {
    // Agregar query param para diferenciar cliente de admin
    window.open(`${apiURL}/api/factura/${id_pago}?cliente=true`, '_blank');
  };
  
  if (cargandoInicial) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-solid mx-auto mb-4"></div>
          <p className="text-lg text-gray-700 font-semibold">Cargando datos...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return <p className="text-center text-red-500">{error}</p>;
  }

  return (
    <div className="p-4 sm:p-8">
      <h1 className="text-lg sm:text-2xl font-bold">BIENVENIDO, {clienteInfo.nombre.toUpperCase()} {clienteInfo.apellidos.toUpperCase()}</h1>
      <p className="mt-4 text-sm sm:text-base">Aquí puedes ver el estado de tus pagos.</p>

      {/* Leyenda de colores */}
      <div className="mt-6 flex flex-wrap space-x-4 items-center">
        <span className="flex items-center">
          <span className="w-4 h-4 bg-green-500 mr-1"></span> Pagado
        </span>
        <span className="flex items-center">
          <span className="w-4 h-4 bg-orange-500 mr-1"></span> Pendiente
        </span>
        <span className="flex items-center">
          <span className="w-4 h-4 border border-black bg-white mr-1"></span> Futuro
        </span>
        <span className="flex items-center">
          <span className="w-4 h-4 bg-gray-300 mr-1"></span> No aplica
        </span>
      </div>

      {/* Estado de pagos por mes */}
      <div className="flex justify-center space-x-4 mb-4 mt-6">
        <button onClick={() => cambiarAño(añoActual - 1)} className="text-blue-500 hover:text-blue-700">◀</button>
        <span className="text-lg sm:text-xl font-semibold">Año {añoActual}</span>
        <button onClick={() => cambiarAño(añoActual + 1)} className="text-blue-500 hover:text-blue-700">▶</button>
      </div>

      <div className="mt-4 grid grid-cols-2 sm:grid-cols-4 gap-4">
        {meses.map((nombreMes, index) => {
          const estado = obtenerEstadoMes(index + 1);
          return (
            <div
              key={index}
              className={`p-4 text-center rounded-lg ${obtenerClaseEstado(estado)}`}
            >
              {nombreMes}
            </div>
          );
        })}
      </div>

      {/* Tabla de pagos recientes */}
      <div className="mt-6">
        <h2 className="text-lg sm:text-xl font-semibold mb-4">Pagos Recientes</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-2 border text-sm sm:text-base">Mes(es) Pagado(s)</th>
                <th className="px-4 py-2 border text-sm sm:text-base">Año</th>
                <th className="px-4 py-2 border text-sm sm:text-base">Fecha de Pago</th>
                <th className="px-4 py-2 border text-sm sm:text-base">Monto Total</th>
                <th className="px-4 py-2 border text-sm sm:text-base">Factura</th>
              </tr>
            </thead>
            <tbody>
              {pagosRecientes.length > 0 ? (
                pagosRecientes.map((pago, index) => (
                  <tr key={index} className="border-b">
                    <td className="px-4 py-2 text-center text-sm sm:text-base">
                      {pago.mes_inicio === pago.mes_fin
                        ? meses[pago.mes_inicio - 1]
                        : `${meses[pago.mes_inicio - 1]} - ${meses[pago.mes_fin - 1]}`}
                    </td>
                    <td className="px-4 py-2 text-center text-sm sm:text-base">{pago.año_inicio}</td>
                    <td className="px-4 py-2 text-center text-sm sm:text-base">
                      {new Date(`${pago.fecha_pago}T00:00:00`).toLocaleDateString('es-MX', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
})}
                    </td>
                    <td className="px-4 py-2 text-center text-sm sm:text-base">
                      ${Number(pago.monto || 0).toFixed(2)}
                    </td>
                    <td className="px-4 py-2 text-center text-sm sm:text-base">
                      <button
                        onClick={() => downloadFactura(pago.id_pago)}
                        className="bg-blue-500 text-white px-2 py-1 rounded"
                      >
                        Ver Factura
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="px-4 py-2 text-center text-gray-500">
                    No hay pagos recientes registrados para este cliente.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default BienvenidoCliente;
