import React, { useState, useEffect } from 'react'; 
import { Link } from 'react-router-dom';
import { UsersIcon, UserGroupIcon, HandThumbDownIcon } from '@heroicons/react/24/solid';
import { Pie, Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import apiURL from "../api/api";


ChartJS.register(ChartDataLabels);

ChartJS.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const FechaHoraActual = () => {
  const [fechaHora, setFechaHora] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setFechaHora(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="text-center mb-6">
      <h2 className="text-xl font-bold">
        {fechaHora.toLocaleDateString()} - {fechaHora.toLocaleTimeString()}
      </h2>
    </div>
  );
};

const Inicio = () => {
  const [clientes, setClientes] = useState(0);
  const [clientesMora, setClientesMora] = useState(0);
  const [recaudacionMensual, setRecaudacionMensual] = useState([]);
  const [gastosMensuales, setGastosMensuales] = useState([]);
  const [gastosAnuales, setGastosAnuales] = useState([]);
  const [totalGastosMensuales, setTotalGastosMensuales] = useState(0);
  const [totalGastosAnuales, setTotalGastosAnuales] = useState(0);
  const [totalRecaudacionAnual, setTotalRecaudacionAnual] = useState(0);
  const [gastosRecientes, setGastosRecientes] = useState([]);
  const [clientesAusentes, setClientesAusentes] = useState(0);

  useEffect(() => {
    async function obtenerDatos() {
      try {
        const mesActual = new Date().getMonth() + 1;
        const añoActual = new Date().getFullYear();
  
        const [
          responseClientes,
          responseMora,
          responseRecaudacion,
          responseGastosMensuales,
          responseGastosAnuales,
          responseGastosRecientes,
          responseAusentes
        ] = await Promise.all([
          fetch(`${apiURL}/api/clientes`),
          fetch(`${apiURL}/api/clientes/mora`),
          fetch(`${apiURL}/api/recaudacion`),
          fetch(`${apiURL}/api/gastos/mensuales/${mesActual}/${añoActual}`),
          fetch(`${apiURL}/api/gastos/anuales/${añoActual}`),
          fetch(`${apiURL}/api/gastos/historial/mensual/${mesActual}/${añoActual}`),
          fetch(`${apiURL}/api/clientes/ausentes/contar`)
        ]);
  
        const clientesData = await responseClientes.json();
        setClientes(clientesData.length);
  
        const moraData = await responseMora.json();
        setClientesMora(moraData.length);
  
        const recaudacionData = await responseRecaudacion.json();
        setRecaudacionMensual(Array.isArray(recaudacionData) ? recaudacionData : []);
        setTotalRecaudacionAnual(
          Array.isArray(recaudacionData) ? recaudacionData.reduce((acc, curr) => acc + curr, 0) : 0
        );
  
        const datosMensuales = await responseGastosMensuales.json();
        setGastosMensuales(datosMensuales);
        setTotalGastosMensuales(datosMensuales.reduce((sum, gasto) => sum + (Number(gasto.total) || 0), 0));
  
        const datosAnuales = await responseGastosAnuales.json();
        setGastosAnuales(datosAnuales);
        setTotalGastosAnuales(datosAnuales.reduce((sum, gasto) => sum + (Number(gasto) || 0), 0));
  
        const gastosMes = await responseGastosRecientes.json();
        setGastosRecientes(gastosMes.slice(0, 3));
  
        const ausentesData = await responseAusentes.json();
        setClientesAusentes(ausentesData.clientes_ausentes);
      } catch (error) {
      }
    }
  
    // Llama a `obtenerDatos` cada 2 segundos (5000 ms)
    const intervalId = setInterval(obtenerDatos, 2000);
    // Llama la primera vez al montar el componente
    obtenerDatos();
  
    return () => clearInterval(intervalId); // Limpia el intervalo al desmontar
  }, []);
  
  const pagosPendientesData = {
    labels: ['', ''],
    datasets: [
      {
        label: 'Porcentaje',
        data: [clientesMora, clientes - clientesMora],
        backgroundColor: ['#eb0c1b', '#36A2EB'], // Rojo y azul
      },
    ],
  };
  
  const opcionesGrafico = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 12,
          },
        },
      },
      datalabels: {
        color: '#fff',
        font: {
          weight: 'bold',
          size: 14,
        },
        formatter: (value, context) => {
          const total = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
          const porcentaje = ((value / total) * 100).toFixed(1);
          return `${porcentaje}%`;
        },
      },
    },
  };

  const recaudacionMensualData = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    datasets: [
      {
        label: 'Recaudación ($)',
        data: recaudacionMensual,
        backgroundColor: 'rgba(40, 167, 69, 0.8)',
        borderColor: 'rgba(75, 192, 192, 1)',
      },
    ],
  };

  const gastosData = {
    labels: gastosMensuales.map(gasto => gasto.tipo_gasto),
    datasets: [
      {
        data: gastosMensuales.map(gasto => gasto.total),
        backgroundColor: [
          '#00A5E0', '#00C49A', '#FF8500', '#E74C3C', '#FFD700', '#A569BD'
        ],
      },
    ],
  };

  const gastosAnualesData = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    datasets: [
      {
        label: 'Gastos ($)',
        data: gastosAnuales,
        backgroundColor: 'rgba(255, 99, 132, 0.8)',
      },
    ],
  };

  
  return (
    <div className="min-h-screen h-full p-4 md:p-6 bg-gray-100">
      <FechaHoraActual />

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
       
      <div className="bg-blue-500 text-white shadow-lg p-6 rounded-lg flex flex-col items-center space-y-2 h-48">
  <UserGroupIcon className="h-12 w-12" />
  <h3 className="text-lg font-bold">Clientes</h3>
  <p className="text-4xl font-extrabold">{clientes}</p>
  <Link
    to="/clientes/lista"
    className="text-sm bg-white text-blue-500 px-4 py-1 rounded-full hover:bg-blue-600 hover:text-white transition duration-200 ease-in-out shadow-md mt-2"
  >
    Mostrar listado
  </Link>
</div>

<div className="bg-red-500 text-white shadow-lg p-6 rounded-lg flex flex-col items-center space-y-2 h-48">
  <HandThumbDownIcon className="h-12 w-12" />
  <h3 className="text-lg font-bold">Clientes en mora</h3>
  <p className="text-4xl font-extrabold">{clientesMora}</p>
  <Link
    to="/clientes/mora"
    className="text-sm bg-white text-red-500 px-4 py-1 rounded-full hover:bg-red-600 hover:text-white transition duration-200 ease-in-out shadow-md mt-2"
  >
    Mostrar listado
  </Link>
</div>

<div className="bg-yellow-500 text-white shadow-lg p-6 rounded-lg flex flex-col items-center space-y-2 h-48">
  <UsersIcon className="h-12 w-12" />
  <h3 className="text-lg font-bold">Clientes en Ausentes</h3>
  <p className="text-4xl font-extrabold">{clientesAusentes}</p>
  <Link
    to="/clientes/ausentes"
    className="text-sm bg-white text-yellow-500 px-4 py-1 rounded-full hover:bg-yellow-600 hover:text-white transition duration-200 ease-in-out shadow-md mt-2"
  >
    Mostrar listado
  </Link>
</div>


      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div className="bg-white shadow-lg p-6 rounded-lg">
      <h3 className="text-lg font-bold text-center mb-4">
  Pagos Pendientes
  <span className="block text-sm font-normal mt-2">
    <span style={{ color: '#eb0c1b', fontWeight: 'bold' }}>
      Clientes en Mora: {clientesMora}
    </span>{' '}
    |{' '}
    <span style={{ color: '#36A2EB', fontWeight: 'bold' }}>
      Clientes al Día: {clientes - clientesMora}
    </span>
  </span>
</h3>

  <div className="h-80 flex justify-center items-center">
    <Pie data={pagosPendientesData} options={opcionesGrafico} />
  </div>
</div>


        <div className="bg-white shadow-lg p-6 rounded-lg">
          <h3 className="text-lg font-bold text-center mb-4">Ingresos Anual</h3>
          <p className="text-center font-semibold text-xl">Total: ${Number(totalRecaudacionAnual).toFixed(2)}</p>
          <div className="h-[300px] md:h-[400px] w-full">
            <Bar
              data={recaudacionMensualData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  x: { ticks: { font: { size: 10 } } },
                  y: { ticks: { font: { size: 10 } } },
                },
                plugins: {
                  legend: {
                    position: 'top',
                    labels: { font: { size: 12 } },
                  },
                },
              }}
            />
          </div>
        </div>

        <div className="bg-white shadow-lg p-6 rounded-lg">
          <h3 className="text-lg font-bold text-center mb-4">Gastos del Mes de {new Date().toLocaleString('es-ES', { month: 'long' })}</h3>
          <p className="text-center font-semibold text-xl">Total: ${Number(totalGastosMensuales).toFixed(2)}</p>
          <div className="h-80 flex justify-center items-center">
            <Doughnut data={gastosData} options={{ maintainAspectRatio: false, cutout: '50%' }} />
          </div>
        </div>

        <div className="bg-white shadow-lg p-6 rounded-lg">
          <h3 className="text-lg font-bold text-center mb-4">Gastos Anuales</h3>
          <p className="text-center font-semibold text-xl">Total: ${Number(totalGastosAnuales).toFixed(2)}</p>
          <div className="h-72">
            <Bar
              data={gastosAnualesData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  x: { ticks: { font: { size: 10 } } },
                  y: { ticks: { font: { size: 10 } } },
                },
                plugins: { legend: { position: 'top', labels: { font: { size: 12 } } } },
              }}
            />
          </div>
        </div>
      </div>

      <div className="mt-8 bg-white shadow-lg p-6 rounded-lg">
        <h3 className="text-lg font-bold mb-4">Historial de Gastos Recientes</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-2 text-left">Tipo de Gasto</th>
                <th className="px-4 py-2 text-left">Monto</th>
                <th className="px-4 py-2 text-left">Fecha</th>
                <th className="px-4 py-2 text-left">Descripción</th>
              </tr>
            </thead>
            <tbody>
              {gastosRecientes.length > 0 ? (
                gastosRecientes.map((gasto, index) => (
                  <tr key={index} className="border-b">
                    <td className="px-4 py-2">{gasto.tipo_gasto}</td>
                    <td className="px-4 py-2">${Number(gasto.monto).toFixed(2)}</td>
                    <td className="px-4 py-2">{new Date(gasto.fecha_gasto).toLocaleDateString()}</td>
                    <td className="px-4 py-2">{gasto.descripcion || 'N/A'}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-4">No hay gastos recientes para este mes.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="mt-4 flex justify-end">
          <Link
            to="/gastos/historial"
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200"
          >
            Ver más
          </Link>
        </div>
        <footer className="text-center text-sm md:text-base mt-8">
  <p>Todos los derechos reservados © 2024. ServiAguaBoquilla.</p>
</footer>

      </div>
    </div>
  );
};

export default Inicio;
