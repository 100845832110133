import React, { useState, useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import apiURL from "../../api/api";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BalanceGeneral = () => {
  const [ingresosMensuales, setIngresosMensuales] = useState([]);
  const [gastosMensuales, setGastosMensuales] = useState([]);
  const [saldoMensual, setSaldoMensual] = useState([]);
  const [saldoAnual, setSaldoAnual] = useState(0);
  const [año, setAño] = useState(new Date().getFullYear());
  const [logoUrl, setLogoUrl] = useState('');
  const chartRef = useRef(null);

  const formatoMoneda = (monto) => {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(monto);
  };

  const generarAños = (rango = 10) => {
    const añoActual = new Date().getFullYear();
    const años = [];
    for (let i = añoActual - rango; i <= añoActual + rango; i++) {
      años.push(i);
    }
    return años;
  };

  // Función para obtener los datos del logo
  const fetchLogo = async () => {
    try {
      const response = await axios.get(`${apiURL}/api/logos/active`);
      setLogoUrl(response.data.path);
    } catch (error) {
      console.error('Error al obtener el logo activo:', error);
    }
  };

  // Función para obtener los datos de balance general
  const fetchBalanceGeneral = async () => {
    try {
      const response = await fetch(`${apiURL}/api/balance-general/${año}`);
      const data = await response.json();

      if (data.error) {
        console.error('Error en la API:', data.error);
        setIngresosMensuales(Array(12).fill(0));
        setGastosMensuales(Array(12).fill(0));
        setSaldoMensual(Array(12).fill(0));
        setSaldoAnual(0);
      } else {
        setIngresosMensuales(data.ingresosMensuales || Array(12).fill(0));
        setGastosMensuales(data.gastosMensuales || Array(12).fill(0));
        setSaldoMensual(data.saldoMensual || Array(12).fill(0));
        setSaldoAnual(data.saldoAnual || 0);
      }
    } catch (error) {
      console.error('Error al obtener el balance general:', error);
      setIngresosMensuales(Array(12).fill(0));
      setGastosMensuales(Array(12).fill(0));
      setSaldoMensual(Array(12).fill(0));
      setSaldoAnual(0);
    }
  };

  useEffect(() => {
    // Llamada inicial para obtener el logo y datos de balance
    fetchLogo();
    fetchBalanceGeneral();

    // Intervalo para actualizar logo y datos cada 30 segundos
    const interval = setInterval(() => {
      fetchLogo();
      fetchBalanceGeneral();
    }, 2000); // 2 segundos de carga

    return () => clearInterval(interval); // Limpieza del intervalo al desmontar el componente
  }, [año]);

  const chartData = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    datasets: [
      {
        label: 'Ingresos ($)',
        data: ingresosMensuales,
        backgroundColor: 'rgba(0, 128, 0, 0.7)',
      },
      {
        label: 'Gastos ($)',
        data: gastosMensuales,
        backgroundColor: 'rgba(255, 0, 0, 0.7)',
      }
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'top' },
      title: { display: true, text: `Ingresos vs Gastos - ${año}` },
    },
  };

  const exportToExcel = () => {
    const excelData = [
      ['Mes', 'Ingresos', 'Gastos', 'Saldo'],
      ...chartData.labels.map((mes, index) => [
        mes,
        ingresosMensuales[index] || 0,
        gastosMensuales[index] || 0,
        (ingresosMensuales[index] || gastosMensuales[index]) ? saldoMensual[index] || 0 : '--',
      ]),
      [
        'Total Anual',
        ingresosMensuales.reduce((acc, ingreso) => acc + (Number(ingreso) || 0), 0),
        gastosMensuales.reduce((acc, gasto) => acc + (Number(gasto) || 0), 0),
        ''
      ]
    ];
  
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `Balance_${año}`);
    XLSX.writeFile(workbook, `Balance_General_${año}.xlsx`);
  };
  
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text(`Balance General - ${año}`, 14, 10);
  
    doc.autoTable({
      startY: 20,
      head: [['Mes', 'Ingresos', 'Gastos', 'Saldo']],
      body: chartData.labels.map((mes, index) => [
        mes,
        formatoMoneda(ingresosMensuales[index] || 0),
        formatoMoneda(gastosMensuales[index] || 0),
        (ingresosMensuales[index] || gastosMensuales[index]) ? formatoMoneda(saldoMensual[index] || 0) : '--',
      ]),
      foot: [[
        'Total Anual',
        formatoMoneda(ingresosMensuales.reduce((acc, ingreso) => acc + (Number(ingreso) || 0), 0)),
        formatoMoneda(gastosMensuales.reduce((acc, gasto) => acc + (Number(gasto) || 0), 0)),
        ''
      ]],
    });
    doc.save(`Balance_General_${año}.pdf`);
  };

  return (
    <div className="p-6 w-full bg-white shadow-lg rounded-lg relative">
    <div className="absolute top-4 right-4 hidden md:block md:top-8 md:right-8 lg:top-10 lg:right-10">
      {logoUrl && (
        <img 
          src={`${apiURL}/logos/${logoUrl}`} 
          alt="Logo" 
          className="sm:h-24 sm:w-30 md:h-15 md:w-15 object-cover"
        />
      )}
    </div>
      <h1 className="text-3xl font-bold mb-6 text-center">Balance General - {año}</h1>
      
      <div className="flex items-center justify-center mb-4 space-x-2">
        <label className="text-lg font-medium">Selecciona Año:</label>
        <select onChange={(e) => setAño(Number(e.target.value))} value={año} className="p-2 border rounded-lg text-lg focus:outline-none focus:border-blue-500">
          {generarAños().map((añoOption) => (
            <option key={añoOption} value={añoOption}>{añoOption}</option>
          ))}
        </select>
      </div>

      <div className="mb-8" style={{ height: '400px' }}>
        <Bar ref={chartRef} data={chartData} options={options} />
      </div>

      <div className="overflow-x-auto">
        <h2 className="text-2xl font-semibold text-center mb-4">Resumen de Ingresos, Gastos y Saldo Mensual</h2>
        <table className="w-full text-left border-collapse">
          <thead>
            <tr>
              <th className="border p-2">Mes</th>
              <th className="border p-2">Ingresos</th>
              <th className="border p-2">Gastos</th>
              <th className="border p-2">Saldo</th>
            </tr>
          </thead>
          <tbody>
            {chartData.labels.map((mes, index) => (
              <tr key={index}>
                <td className="border p-2">{mes}</td>
                <td className="border p-2">{formatoMoneda(ingresosMensuales[index] || 0)}</td>
                <td className="border p-2">{formatoMoneda(gastosMensuales[index] || 0)}</td>
                <td className={`border p-2 ${saldoMensual[index] >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                  {(ingresosMensuales[index] || gastosMensuales[index]) ? formatoMoneda(saldoMensual[index] || 0) : '--'}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th className="border p-2">Total Anual</th>
              <th className="border p-2">{formatoMoneda(ingresosMensuales.reduce((acc, ingreso) => acc + (Number(ingreso) || 0), 0))}</th>
              <th className="border p-2">{formatoMoneda(gastosMensuales.reduce((acc, gasto) => acc + (Number(gasto) || 0), 0))}</th>
              <th className="border p-2"></th>
            </tr>
          </tfoot>
        </table>
      </div>

      <div className="flex justify-center mt-4">
        <button onClick={exportToExcel} className="bg-blue-500 text-white py-2 px-4 rounded mr-2">Descargar Excel</button>
        <button onClick={exportToPDF} className="bg-red-500 text-white py-2 px-4 rounded">Descargar PDF</button>
      </div>
    </div>
  );
};

export default BalanceGeneral;
