import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { HiDownload } from 'react-icons/hi';
import apiURL from "../../api/api";
function HistorialGastos() {
  const [gastos, setGastos] = useState([]);
  const [mensaje, setMensaje] = useState('');
  const [mes, setMes] = useState(new Date().getMonth() + 1);
  const [año, setAño] = useState(new Date().getFullYear());
  const [modo, setModo] = useState("mensual");
  const [pagina, setPagina] = useState(1);
  const [mostrarExportar, setMostrarExportar] = useState(false);
  const registrosPorPagina = 12;

  const meses = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];

  useEffect(() => {
    const obtenerGastos = async () => {
      try {
        const response = modo === "mensual"
          ? await axios.get(`${apiURL}/api/gastos/historial/mensual/${mes}/${año}`)
          : await axios.get(`${apiURL}/api/gastos/historial/anual/${año}`);

        const gastosOrdenados = response.data.sort((a, b) => new Date(a.fecha_gasto) - new Date(b.fecha_gasto));
        setGastos(gastosOrdenados);
        setPagina(1);
      } catch (error) {
       
        setMensaje('Hubo un problema al cargar el historial de gastos.');
      }
    };

    obtenerGastos();
  }, [mes, año, modo]);

  const indexOfLastGasto = pagina * registrosPorPagina;
  const indexOfFirstGasto = indexOfLastGasto - registrosPorPagina;
  const gastosPaginaActual = gastos.slice(indexOfFirstGasto, indexOfLastGasto);

  const handleMesAnterior = () => {
    setMes(mes === 1 ? 12 : mes - 1);
    if (mes === 1) setAño(año - 1);
  };

  const handleMesSiguiente = () => {
    setMes(mes === 12 ? 1 : mes + 1);
    if (mes === 12) setAño(año + 1);
  };

  const handleAñoAnterior = () => setAño(año - 1);
  const handleAñoSiguiente = () => setAño(año + 1);
  const toggleModo = () => setModo(modo === "mensual" ? "anual" : "mensual");

  const handleExport = async (formato) => {
    try {
      const response = await axios.get(
        `${apiURL}/api/gastos/exportar/${formato}/${modo}/${mes}/${año}`,
        { responseType: 'blob' }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `historial_gastos_${modo}_${mes}_${año}.${formato}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
    
    }
  };

  const handlePaginaSiguiente = () => {
    if (pagina < Math.ceil(gastos.length / registrosPorPagina)) setPagina(pagina + 1);
  };
  const handlePaginaAnterior = () => {
    if (pagina > 1) setPagina(pagina - 1);
  };

  return (
    <div className="w-full p-4 md:p-6">
      <h2 className="text-2xl md:text-3xl font-bold mb-4 text-center">Historial de Gastos</h2>
      
      <div className="flex flex-col md:flex-row items-center justify-between mb-4 relative space-y-2 md:space-y-0">
        <div className="flex items-center justify-center space-x-2">
          {modo === "mensual" ? (
            <>
              <button onClick={handleMesAnterior} className="text-blue-500 hover:text-blue-700">
                <FaArrowLeft size={18} />
              </button>
              <span className="font-bold text-lg md:text-2xl mx-2">{meses[mes - 1]} {año}</span>
              <button onClick={handleMesSiguiente} className="text-blue-500 hover:text-blue-700">
                <FaArrowRight size={18} />
              </button>
            </>
          ) : (
            <>
              <button onClick={handleAñoAnterior} className="text-blue-500 hover:text-blue-700">
                <FaArrowLeft size={18} />
              </button>
              <span className="font-bold text-lg md:text-2xl mx-2">{año}</span>
              <button onClick={handleAñoSiguiente} className="text-blue-500 hover:text-blue-700">
                <FaArrowRight size={18} />
              </button>
            </>
          )}
        </div>

        <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-2">
          <button 
            onClick={toggleModo} 
            className="w-full md:w-auto px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 text-sm"
          >
            Ver {modo === "mensual" ? "Anual" : "Mensual"}
          </button>

          <div className="relative w-full md:w-auto">
            <button 
              onClick={() => setMostrarExportar(!mostrarExportar)} 
              className="w-full md:w-auto px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600 flex items-center justify-center text-sm"
            >
              <HiDownload size={18} className="mr-1" /> Exportar
            </button>
            {mostrarExportar && (
              <div className="absolute mt-2 right-0 w-full md:w-32 bg-white shadow-lg rounded-lg z-10">
                <button 
                  onClick={() => handleExport('pdf')} 
                  className="block w-full px-4 py-2 text-left hover:bg-gray-100"
                >
                  PDF
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {mensaje && <p className="text-red-500">{mensaje}</p>}

      <div className="overflow-x-auto w-full">
        <table className="min-w-full bg-white border border-gray-200 text-sm md:text-base">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-2 md:px-4 py-2 text-left">#</th>
              <th className="px-2 md:px-4 py-2 text-left">Tipo de Gasto</th>
              <th className="px-2 md:px-4 py-2 text-left">Monto</th>
              <th className="px-2 md:px-4 py-2 text-left">Fecha</th>
              <th className="px-2 md:px-4 py-2 text-left">Descripción</th>
              <th className="px-2 md:px-4 py-2 text-left">Comprobante</th>
            </tr>
          </thead>
          <tbody>
            {gastosPaginaActual.length > 0 ? (
              gastosPaginaActual.map((gasto, index) => (
                <tr key={gasto.id_gasto || index} className="border-b">
                  <td className="px-2 md:px-4 py-2">{indexOfFirstGasto + index + 1}</td>
                  <td className="px-2 md:px-4 py-2">{gasto.tipo_gasto}</td>
                  <td className="px-2 md:px-4 py-2">${parseFloat(gasto.monto).toFixed(2)}</td>
                 <td className="px-2 md:px-4 py-2">{new Date(gasto.fecha_gasto).toLocaleDateString('es-MX', {
                 day: '2-digit',
                 month: '2-digit',
                 year: 'numeric',
                 timeZone: 'America/Mexico_City' // Cambia esta a tu zona horaria local
                })}
                </td>
                  <td className="px-2 md:px-4 py-2">{gasto.descripcion}</td>
                  <td className="px-2 md:px-4 py-2">
                    {gasto.comprobante_url ? (
                      <a
                        href={`${apiURL}${gasto.comprobante_url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-blue-500 hover:bg-blue-600 text-white px-2 md:px-3 py-1 rounded text-xs md:text-sm"
                      >
                        Ver Comprobante
                      </a>
                    ) : (
                      'No disponible'
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center py-4">
                  No hay gastos registrados para este {modo === "mensual" ? "mes" : "año"}.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="flex flex-col md:flex-row justify-between items-center mt-4 space-y-2 md:space-y-0">
        <button
          onClick={handlePaginaAnterior}
          disabled={pagina === 1}
          className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 disabled:opacity-50 text-sm w-full md:w-auto"
        >
          Anterior
        </button>
        <button
          onClick={handlePaginaSiguiente}
          disabled={pagina === Math.ceil(gastos.length / registrosPorPagina)}
          className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 disabled:opacity-50 text-sm w-full md:w-auto"
        >
          Siguiente
        </button>
      </div>
    </div>
  );
}

export default HistorialGastos;
