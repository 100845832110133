import React, { useState, useEffect } from 'react';
import { FaFileExcel, FaFilePdf, FaEdit, FaTrashAlt, FaUsers, FaSearch } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import apiURL from "../api/api";

function ListaClientes() {
  const [clientes, setClientes] = useState([]);
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
  const [mostrarModal, setMostrarModal] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [busqueda, setBusqueda] = useState('');
  const [paginaActual, setPaginaActual] = useState(1);
  const clientesPorPagina = 50;

  useEffect(() => {
    const obtenerClientes = async () => {
      setCargando(true);
      try {
        const response = await fetch(`${apiURL}/api/clientes/lista`);
        if (!response.ok) {
          throw new Error('Error al obtener la lista de clientes.');
        }
        const data = await response.json();
        const clientesOrdenados = data.sort((a, b) => a.nombre.localeCompare(b.nombre));
        setClientes(clientesOrdenados);
      } catch (error) {
        setMensaje('Hubo un problema al cargar los clientes.');
        Swal.fire('Error', 'Hubo un problema al cargar los clientes.', 'error');
      } finally {
        setCargando(false);
      }
    };
    obtenerClientes();
  }, []);

  const exportarPDF = () => {
    const doc = new jsPDF();
    doc.text('Lista de usuarios del servicio de Agua Potable', 45, 15);
  
    // Agrega las columnas de los meses
    const tablaColumnas = [
      'No',
      'Nombre Completo',
      'Referencia',
      'E', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'
    ];
  
    // Mapea las filas incluyendo los valores de los meses
    const tablaFilas = clientes.map((cliente, index) => {
      const meses = ['E', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']; // Iniciales de los meses
      return [
        index + 1,
        `${cliente.nombre.toUpperCase()} ${cliente.apellidos.toUpperCase()}`,
        cliente.descripcion ? cliente.descripcion.toUpperCase() : '',
        ...meses // Inserta las iniciales de los meses en las columnas correspondientes
      ];
    });
  
    // Genera la tabla con los estilos de color personalizados
    doc.autoTable({
      head: [tablaColumnas],
      body: tablaFilas,
      startY: 20,
      styles: {
        lineWidth: 0.1, // Grosor de las líneas
        lineColor: [0, 0, 0], // Color de las líneas (negro)
        fontSize: 9, // Tamaño de la fuente
        cellPadding: 0.7, // Reduce el espacio interno en las celdas
      },
      headStyles: {
        fillColor: [41, 128, 185], // Color de fondo del encabezado (azul)
        textColor: [255, 255, 255], // Color del texto del encabezado (blanco)
        fontStyle: 'bold', // Texto en negritas
        halign: 'center', // Centra el texto del encabezado
      },
      alternateRowStyles: {
        fillColor: [220, 230, 241], // Color alternado para las filas (azul claro)
      },
      bodyStyles: {
        fillColor: [255, 255, 255], // Color de fondo de las filas estándar (blanco)
        textColor: [0, 0, 0], // Color del texto
        halign: 'center', // Centra los valores en las celdas por defecto
      },
      columnStyles: {
        1: { halign: 'left', cellPadding: 0.7 }, // Alinea los nombres de los clientes a la izquierda y reduce el padding
      },
    });
  
    // Guarda el archivo PDF
    doc.save('clientes.pdf');
  };
  
  const exportarExcel = () => {
    // Define las columnas para el Excel, incluyendo los meses únicos
    const data = clientes.map((cliente, index) => {
      const clienteData = {
        No: index + 1,
        'Nombre Completo': `${cliente.nombre.toUpperCase()} ${cliente.apellidos.toUpperCase()}`,
        Referencia: cliente.descripcion ? cliente.descripcion.toUpperCase() : '',
      };
  
      // Agregar los meses con sus valores (E, F, M1, A, M2, J1, J2, ...)
      const meses = ['E', 'F', 'M', 'A', 'M2', 'J1', 'J2', 'A2', 'S', 'O', 'N', 'D'];
      meses.forEach((mes, i) => {
        clienteData[mes] = mes; // Colocar las iniciales de los meses
      });
  
      return clienteData;
    });
  
    // Crear hoja de Excel
    const worksheet = XLSX.utils.json_to_sheet(data);
  
    // Crear el libro de trabajo y agregar la hoja
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Clientes');
  
    // Descargar el archivo Excel
    XLSX.writeFile(workbook, 'clientes.xlsx');
  };
  
  
  const handleSearch = (e) => {
    setBusqueda(e.target.value);
    setPaginaActual(1); // Reinicia a la primera página al realizar una nueva búsqueda
  };

  const clientesFiltrados = clientes.filter(
    (cliente) =>
      cliente.nombre.toLowerCase().includes(busqueda.toLowerCase()) ||
      cliente.apellidos.toLowerCase().includes(busqueda.toLowerCase())
  );

  // Calcular los clientes en la página actual
  const indexOfLastCliente = paginaActual * clientesPorPagina;
  const indexOfFirstCliente = indexOfLastCliente - clientesPorPagina;
  const clientesPagina = clientesFiltrados.slice(indexOfFirstCliente, indexOfLastCliente);
  const totalPaginas = Math.ceil(clientesFiltrados.length / clientesPorPagina);

  const avanzarPagina = () => {
    if (paginaActual < totalPaginas) {
      setPaginaActual(paginaActual + 1);
    }
  };

  const retrocederPagina = () => {
    if (paginaActual > 1) {
      setPaginaActual(paginaActual - 1);
    }
  };

  const abrirModalEdicion = (cliente) => {
    setClienteSeleccionado(cliente);
    setMostrarModal(true);
  };

  const cerrarModal = () => {
    setMostrarModal(false);
    setClienteSeleccionado(null);
  };

  const manejarCambio = (e) => {
    const { name, value } = e.target;
    setClienteSeleccionado({ ...clienteSeleccionado, [name]: value });
  };

  const guardarCambios = async () => {
    if (!clienteSeleccionado) return;

    setCargando(true);
    try {
      const response = await fetch(`${apiURL}/api/clientes/${clienteSeleccionado.id_cliente}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(clienteSeleccionado),
      });
      if (!response.ok) {
        throw new Error('Error al actualizar el cliente.');
      }
      const dataActualizada = await response.json();
      setClientes(clientes.map((c) => (c.id_cliente === dataActualizada.id_cliente ? dataActualizada : c)));
      cerrarModal();
      Swal.fire('Éxito', 'Cliente actualizado con éxito.', 'success');
    } catch (error) {
      Swal.fire('Error', 'Hubo un problema al actualizar el cliente.', 'error');
    } finally {
      setCargando(false);
    }
  };

  const eliminarCliente = async (id_cliente) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'No podrás revertir esto.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminarlo!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setCargando(true);
        try {
          const response = await fetch(`${apiURL}/api/clientes/${id_cliente}`, { method: 'DELETE' });
          if (!response.ok) {
            throw new Error('Error al eliminar el cliente.');
          }
          setClientes(clientes.filter((cliente) => cliente.id_cliente !== id_cliente));
          Swal.fire('Eliminado', 'El cliente ha sido eliminado.', 'success');
        } catch (error) {
          Swal.fire('Error', 'Hubo un problema al eliminar el cliente.', 'error');
        } finally {
          setCargando(false);
        }
      }
    });
  };

  return (
    <div className="w-full p-6">
      <div className="flex justify-center items-center mb-6">
        <h2 className="text-3xl font-bold flex items-center">
          <FaUsers className="mr-2" />
          Lista de Clientes
        </h2>
      </div>

      {cargando && (
        <div className="flex justify-center items-center mb-4">
          <div className="loader border-t-4 border-blue-500 rounded-full w-12 h-12 animate-spin"></div>
          <span className="ml-4 text-blue-500 font-semibold">Cargando...</span>
        </div>
      )}

      <div className="flex flex-col sm:flex-row sm:justify-between items-center mb-4 space-y-4 sm:space-y-0">
        <div className="relative w-full sm:w-auto sm:max-w-md">
          <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Buscar cliente"
            value={busqueda}
            onChange={handleSearch}
            className="p-2 pl-10 border rounded w-full sm:w-auto"
          />
        </div>
        <div className="flex space-x-2">
          <button
            onClick={exportarExcel}
            className="flex items-center bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
          >
            <FaFileExcel className="mr-2" /> Exportar Excel
          </button>
          <button
            onClick={exportarPDF}
            className="flex items-center bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
          >
            <FaFilePdf className="mr-2" /> Exportar PDF
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-4 py-2 text-left">No</th>
              <th className="px-4 py-2 text-left">Nombre Completo</th>
              <th className="px-4 py-2 text-left">Referencia</th>
              <th className="px-4 py-2 text-center">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {clientesPagina.length > 0 ? (
              clientesPagina.map((cliente, index) => (
                <tr key={index} className="border-b">
                  <td className="px-4 py-2">{indexOfFirstCliente + index + 1}</td>
                  <td className="px-4 py-2">{`${cliente.nombre.toUpperCase()} ${cliente.apellidos.toUpperCase()}`}</td>
                  <td className="px-4 py-2">{(cliente.descripcion || '').toUpperCase()}</td>
                  <td className="px-4 py-2 flex space-x-2 justify-center">
                    <button
                      onClick={() => abrirModalEdicion(cliente)}
                      className="flex items-center bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded"
                    >
                      <FaEdit className="mr-2" /> Editar
                    </button>
                    <button
                      onClick={() => eliminarCliente(cliente.id_cliente)}
                      className="flex items-center bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded"
                    >
                      <FaTrashAlt className="mr-2" /> Eliminar
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center py-4">
                  {mensaje ? mensaje : 'No hay clientes registrados.'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {totalPaginas > 1 && (
        <div className="flex justify-center mt-4 space-x-4">
          {paginaActual > 1 && (
            <button
              onClick={retrocederPagina}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Anterior
            </button>
          )}
          <span className="text-lg font-semibold">Página {paginaActual} de {totalPaginas}</span>
          {paginaActual < totalPaginas && (
            <button
              onClick={avanzarPagina}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Siguiente
            </button>
          )}
        </div>
      )}

      {mostrarModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg w-80 max-w-full">
            <h2 className="text-2xl mb-4">Editar Cliente</h2>
            <div>
              <label className="block mb-2">Nombre:</label>
              <input
                type="text"
                name="nombre"
                value={clienteSeleccionado?.nombre || ""}
                onChange={manejarCambio}
                className="border px-2 py-1 rounded w-full mb-2"
              />
              <label className="block mb-2">Apellidos:</label>
              <input
                type="text"
                name="apellidos"
                value={clienteSeleccionado?.apellidos || ""}
                onChange={manejarCambio}
                className="border px-2 py-1 rounded w-full mb-2"
              />
              <label className="block mb-2">Descripción:</label>
              <input
                type="text"
                name="descripcion"
                value={clienteSeleccionado?.descripcion || ""}
                onChange={manejarCambio}
                className="border px-2 py-1 rounded w-full mb-4 uppercase"
              />
              <div className="flex justify-end space-x-2">
                <button
                  onClick={guardarCambios}
                  className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
                >
                  Guardar Cambios
                </button>
                <button
                  onClick={cerrarModal}
                  className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ListaClientes;
