import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import apiURL from "../../api/api";

function RegistrarPagoCliente() {
  const { id_cliente } = useParams();
  const [cliente, setCliente] = useState(null);
  const [pagos, setPagos] = useState([]);
  const [mesesSeleccionados, setMesesSeleccionados] = useState([]);
  const [total, setTotal] = useState(0);
  const [añoMesSeleccionado, setAñoMesSeleccionado] = useState(new Date().getFullYear());
  const [añoHistorial, setAñoHistorial] = useState(new Date().getFullYear());
  const [modoSeleccion, setModoSeleccion] = useState(null);
  const [esAusente, setEsAusente] = useState(false);
  const navigate = useNavigate();


  const costoPorMes = 50;
  const añoActual = new Date().getFullYear();

  useEffect(() => {
    fetchClienteYPagos();
  }, [id_cliente]);

  const handleModoSeleccion = (modo) => {
    // Si el modo actual ya está seleccionado, lo deselecciona al volver a presionar
    setModoSeleccion(modoSeleccion === modo ? null : modo);
  };

  const confirmarAccion = () => {
    if (modoSeleccion === 'ausencia') {
    } else if (modoSeleccion === 'reactivacion') {
     
    }
    setModoSeleccion(null); // Oculta el botón de confirmación después de confirmar
  };
  
  const fetchClienteYPagos = async () => {
    try {
      const response = await fetch(`${apiURL}/api/pagos/${id_cliente}`);
      const data = await response.json();
      setCliente(data.cliente);
      setPagos(data.pagos);

      // Verifica si el cliente está en ausencia
      const ultimoAusente = data.pagos.find((pago) => pago.es_ausencia && !pago.mes_fin);
      setEsAusente(!!ultimoAusente);
    } catch (error) {
     
    }
  };

  const estadoMes = (mes, año) => {
    const añoRegistroCliente = cliente ? new Date(cliente.fecha_registro).getFullYear() : null;
    const mesRegistroCliente = cliente ? new Date(cliente.fecha_registro).getMonth() + 1 : null;
    const ausencia = pagos.find((pago) => pago.es_ausencia);
    const reactivacion = pagos.find((pago) => pago.es_reactivacion);
    const mesActual = new Date().getMonth() + 1;
    const añoActual = new Date().getFullYear();

    // 1. Bloquear meses anteriores al registro del cliente
    if (añoRegistroCliente && (año < añoRegistroCliente || (año === añoRegistroCliente && mes < mesRegistroCliente))) {
        return 'no_aplica'; // Meses antes del registro
    }

    // 2. Marcar el mes del registro como pagado
    if (año === añoRegistroCliente && mes === mesRegistroCliente) {
        return 'pagado'; // Mes del registro pagado por defecto
    }

    // 3. Bloquear meses en ausencia activa
    if (ausencia) {
        const { mes_inicio: mesAusenciaInicio, año_inicio: añoAusenciaInicio, mes_fin: mesAusenciaFin, año_fin: añoAusenciaFin } = ausencia;

        // Ajustar el fin de la ausencia según la reactivación
        let mesFinEvaluado = mesAusenciaFin;
        let añoFinEvaluado = añoAusenciaFin;

        if (reactivacion) {
            const { mes_inicio: mesReactivacion, año_inicio: añoReactivacion } = reactivacion;

            // Si la reactivación ocurre antes de que termine la ausencia
            if (
                !mesAusenciaFin ||
                !añoAusenciaFin ||
                añoReactivacion < añoAusenciaFin ||
                (añoReactivacion === añoAusenciaFin && mesReactivacion <= mesAusenciaFin)
            ) {
                mesFinEvaluado = mesReactivacion - 1;
                añoFinEvaluado = mesReactivacion === 1 ? añoReactivacion - 1 : añoReactivacion;
            }
        }

        // Bloquear meses dentro del rango ajustado de ausencia activa
        if (
            (año > añoAusenciaInicio || (año === añoAusenciaInicio && mes >= mesAusenciaInicio)) &&
            (mesFinEvaluado === null || año < añoFinEvaluado || (año === añoFinEvaluado && mes <= mesFinEvaluado))
        ) {
            return 'no_aplica'; // Meses dentro de la ausencia activa
        }
    }

    // 4. Mes ya pagado
    const pagoRealizado = pagos.find((pago) => {
        const mesInicio = pago.mes_inicio;
        const mesFin = pago.mes_fin || mesInicio;
        const añoInicio = pago.año_inicio;
        const añoFin = pago.año_fin || añoInicio;

        return (
            (año > añoInicio || (año === añoInicio && mes >= mesInicio)) &&
            (año < añoFin || (año === añoFin && mes <= mesFin)) &&
            pago.estado_pago === 'pagado'
        );
    });

    if (pagoRealizado) {
        return 'pagado'; // Mes pagado
    }

    // 5. Marcar el mes de reactivación como pagado
    if (reactivacion) {
        const { mes_inicio: mesReactivacion, año_inicio: añoReactivacion } = reactivacion;

        // Bloquear meses anteriores a la reactivación
        if (año < añoReactivacion || (año === añoReactivacion && mes < mesReactivacion)) {
            return 'no_aplica';
        }

        // Marcar el mes de reactivación como pagado
        if (año === añoReactivacion && mes === mesReactivacion) {
            return 'pagado';
        }
    }

    // 6. Mes futuro o actual que no ha terminado
if (año > añoActual || (año === añoActual && mes > mesActual)) {
  return 'futuro'; // Mes futuro
}

if (año === añoActual && mes === mesActual) {
  const pagoRealizado = pagos.find((pago) => {
      const mesInicio = pago.mes_inicio;
      const mesFin = pago.mes_fin || mesInicio;
      const añoInicio = pago.año_inicio;
      const añoFin = pago.año_fin || añoInicio;

      return (
          (año > añoInicio || (año === añoInicio && mes >= mesInicio)) &&
          (año < añoFin || (año === añoFin && mes <= mesFin)) &&
          pago.estado_pago === 'pagado'
      );
  });

  // Si el mes actual no está pagado, pero aún no ha terminado, lo mostramos como futuro
  if (!pagoRealizado) {
      return 'futuro'; // Mes actual sin pago registrado
  }
}

// 7. Mes pendiente (solo meses pasados no pagados)
if (año === añoActual && mes < mesActual) {
  const pagoRealizado = pagos.find((pago) => {
      const mesInicio = pago.mes_inicio;
      const mesFin = pago.mes_fin || mesInicio;
      const añoInicio = pago.año_inicio;
      const añoFin = pago.año_fin || añoInicio;

      return (
          (año > añoInicio || (año === añoInicio && mes >= mesInicio)) &&
          (año < añoFin || (año === añoFin && mes <= mesFin)) &&
          pago.estado_pago === 'pagado'
      );
  });

  if (!pagoRealizado) {
      return 'pendiente'; // Mes pasado sin pago registrado
  }
}


    // 8. Por defecto
    return 'no_aplica';
};


////SE CAMBIO
const toggleMes = (mes) => {
  const mesSeleccionado = { mes, año: añoMesSeleccionado };
  const existe = mesesSeleccionados.find(
    (item) => item.mes === mes && item.año === añoMesSeleccionado
  );

  if (existe) {
    setMesesSeleccionados(mesesSeleccionados.filter((item) => item !== existe));
  } else {
    setMesesSeleccionados([...mesesSeleccionados, mesSeleccionado]);
  }
};


const realizarReactivacion = async () => {
  if (!mesesSeleccionados.length) {
    Swal.fire('Error', 'Debes seleccionar el mes para reactivar.', 'error');
    return;
  }

  const mesReactivacion = mesesSeleccionados[0].mes;
  const añoReactivacion = mesesSeleccionados[0].año;

  try {
    const response = await fetch(`${apiURL}/api/pagos/reactivar/${id_cliente}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        mes_inicio: mesReactivacion,
        año_inicio: añoReactivacion,
        fecha_pago: new Date().toISOString().split('T')[0],
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || 'Error al registrar la reactivación');
    }

    Swal.fire(
      'Reactivación registrada',
      `Reactivado desde ${mesReactivacion}/${añoReactivacion}`,
      'success'
    );
    setMesesSeleccionados([]);
    fetchClienteYPagos(); // Refrescar los datos aquí
    setModoSeleccion(null);
    setEsAusente(false);
  } catch (error) {
    Swal.fire('Error', error.message, 'error');
  }
};
const verificarContinuidadMeses = (meses) => {
  const sortedMeses = meses.sort((a, b) =>
    a.año !== b.año ? a.año - b.año : a.mes - b.mes
  );

  for (let i = 1; i < sortedMeses.length; i++) {
    const { mes: mesActual, año: añoActual } = sortedMeses[i];
    const { mes: mesAnterior, año: añoAnterior } = sortedMeses[i - 1];

    // Verifica si el mes actual es el siguiente del anterior o una transición de diciembre a enero
    const esConsecutivo =
      (añoActual === añoAnterior && mesActual === mesAnterior + 1) ||
      (añoActual === añoAnterior + 1 && mesActual === 1 && mesAnterior === 12);

    if (!esConsecutivo) {
      return false;
    }
  }

  return true;
};

  /////APENAS LO PUSE
  const realizarPago = async () => {
    if (!mesesSeleccionados.length) {
      Swal.fire('Error', 'Debes seleccionar al menos un mes.', 'error');
      return;
    }
  
    // Verifica si los meses seleccionados son continuos
    if (!verificarContinuidadMeses(mesesSeleccionados)) {
      Swal.fire(
        'Error',
        'Los meses seleccionados deben ser consecutivos.',
        'error'
      );
      return;
    }
  
    // Ordenar meses seleccionados
    const sortedMeses = mesesSeleccionados.sort((a, b) =>
      a.año !== b.año ? a.año - b.año : a.mes - b.mes
    );
  
    // Determinar el rango
    const mesInicio = sortedMeses[0].mes;
    const mesFin = sortedMeses[sortedMeses.length - 1].mes;
    const añoInicio = sortedMeses[0].año;
    const añoFin = sortedMeses[sortedMeses.length - 1].año;
  
    const montoTotal = sortedMeses.length * costoPorMes;
  
    try {
      // Registrar el rango como un único pago
      const response = await fetch(`${apiURL}/api/pagos`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id_cliente: id_cliente,
          mes_inicio: mesInicio,
          mes_fin: mesFin,
          año_inicio: añoInicio,
          año_fin: añoFin,
          monto: montoTotal,
          fecha_pago: new Date().toISOString().split('T')[0],
        }),
      });
  
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Error al registrar el pago.');
      }
  
      Swal.fire('Éxito', 'Pago registrado correctamente.', 'success');
      fetchClienteYPagos();
      setMesesSeleccionados([]);
      setTotal(0);
    } catch (error) {
      Swal.fire('Error', error.message, 'error');
    }
  };
  
  const handleAusencia = async () => {
    if (!mesesSeleccionados.length) {
      Swal.fire('Error', 'Debes seleccionar al menos un mes para marcar como ausencia.', 'error');
      return;
    }

    const sortedMeses = mesesSeleccionados.sort((a, b) =>
      a.año !== b.año ? a.año - b.año : a.mes - b.mes
    );
    const mesInicio = sortedMeses[0].mes;
    const añoInicio = sortedMeses[0].año;

    try {
  await fetch(`${apiURL}/api/pagos/ausente/${id_cliente}`, { // Comillas invertidas
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      mes_inicio: mesInicio,
      año_inicio: añoInicio,
      fecha_pago: new Date().toISOString().split('T')[0],
    }),
  });

  Swal.fire(
    'Ausencia registrada',
    `Ausente desde ${mesInicio}/${añoInicio}`, // Comillas invertidas
    'success'
  );

  setMesesSeleccionados([]); // Limpiar selección
  fetchClienteYPagos(); // Refrescar datos
  setModoSeleccion(null); // Salir del modo de ausencia
} catch (error) {
  Swal.fire('Error', 'No se pudo registrar la ausencia', 'error');
}

  };

  useEffect(() => {
    setTotal(mesesSeleccionados.length * costoPorMes);
  }, [mesesSeleccionados]);

  const viewFactura = (id_pago) => {
    window.open(`${apiURL}/api/factura/${id_pago}`, '_blank');
  };
  

  const pagosFiltrados = pagos
    .filter((pago) => {
      const añoFechaPago = new Date(pago.fecha_pago).getFullYear();
      return añoFechaPago === añoHistorial;
    })
    .sort((a, b) => new Date(b.fecha_pago) - new Date(a.fecha_pago));

    ///////////////////NUEVA QUE NDO PONIENDO
    const insertarPago = async (idCliente, mesInicio, añoInicio, monto) => {
      try {
        const response = await fetch(`${apiURL}/api/pagos`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id_cliente: idCliente,
            mes_inicio: mesInicio,
            año_inicio: añoInicio,
            monto,
            estado_pago: 'pagado',
            fecha_pago: new Date().toISOString().split('T')[0],
          }),
        });
    
        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.error || 'Error al registrar el pago.');
        }
      } catch (error) {
        Swal.fire('Error', error.message, 'error');
        
      }
    };
    
    
    
    const actualizarPago = async (idCliente, mesInicio, añoInicio, monto) => {
      try {
        const response = await fetch(`${apiURL}/api/pagos/actualizar-pago`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id_cliente: idCliente,
            mes_inicio: mesInicio,
            año_inicio: añoInicio,
            monto,
          }),
        });
    
        if (response.ok) {
          Swal.fire('Éxito', 'Pago actualizado correctamente', 'success');
        } else {
          const error = await response.json();
          Swal.fire('Error', error.error, 'error');
        }
      } catch (error) {
        Swal.fire('Error', 'Hubo un error al actualizar el pago.', 'error');
       
      }
    };
    
    
  return (
    <div>
  <button onClick={() => navigate('/pagos/registrar')} className="bg-gray-300 text-black px-4 py-2 rounded mb-4">
    Volver a la lista de clientes
  </button>

  {cliente ? (
    <div>
     <h2 className="text-2xl font-bold mb-4 uppercase">Registrar Pago para : {cliente.nombre.toUpperCase()} {cliente.apellidos.toUpperCase()}</h2>
      <div className="mb-4">
        <p><strong>Número de Cliente:</strong> {cliente.numero_cliente}</p>
        <p><strong>Contraseña Generada:</strong> {cliente.password_generada || 'No disponible'}</p>
      </div>

      <div className="mb-4 flex items-center space-x-4 justify-end">
        <button
          onClick={() => setModoSeleccion('ausencia')}
          className="bg-red-500 text-white px-4 py-2 rounded"
        >
          Ausentar
        </button>
       
      </div>

      <div className="flex justify-end">
        {modoSeleccion === 'ausencia' && (
          <button
            onClick={handleAusencia}
            className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
          >
            Confirmar Ausencia
          </button>
        )}
        {modoSeleccion === 'reactivacion' && (
          <button
            onClick={realizarReactivacion}
            className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
          >
            Confirmar Reactivación
          </button>
        )}
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold">Leyenda de colores:</h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
          <span className="flex items-center">
            <span className="inline-block w-4 h-4 bg-green-500 mr-2"></span> Pagado
          </span>
          <span className="flex items-center">
            <span className="inline-block w-4 h-4 bg-orange-500 mr-2"></span> Pendiente
          </span>
          <span className="flex items-center">
            <span className="inline-block w-4 h-4 bg-gray-400 mr-2"></span> No aplica
          </span>
          <span className="flex items-center">
            <span className="inline-block w-4 h-4 bg-blue-500 mr-2"></span> Seleccionado para pago
          </span>
          <span className="flex items-center">
            <span className="inline-block w-4 h-4 border-2 border-black bg-white mr-2"></span> Futuro
          </span>
        </div>
      </div>

      <div className="flex justify-center space-x-4 mb-4">
        <button onClick={() => setAñoMesSeleccionado(añoMesSeleccionado - 1)} className="text-blue-500 hover:text-blue-700">◀</button>
        <h3 className="text-2xl font-semibold"> Año {añoMesSeleccionado}</h3>
        <button onClick={() => setAñoMesSeleccionado(añoMesSeleccionado + 1)} className="text-blue-500 hover:text-blue-700">▶</button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-4">
        {Array.from({ length: 12 }, (_, i) => i + 1).map((mes) => {
          const estado = estadoMes(mes, añoMesSeleccionado);
          const isSelected = mesesSeleccionados.some((item) => item.mes === mes && item.año === añoMesSeleccionado);

          const estilo =
            estado === 'pagado'
              ? 'bg-green-500 text-white'
              : estado === 'pendiente' && isSelected
              ? 'bg-blue-500 text-white'
              : estado === 'pendiente'
              ? 'bg-orange-500 text-white'
              : estado === 'no_aplica'
              ? 'bg-gray-400 text-white cursor-not-allowed'
              : isSelected
              ? 'bg-blue-500 text-white'
              : 'bg-white border-2 border-black';

          return (
            <button
              key={`${mes}-${añoMesSeleccionado}`}
              className={`p-2 rounded ${estilo}`}
              onClick={() => toggleMes(mes)}
              disabled={estado === 'pagado' || estado === 'no_aplica'}
            >
              {new Date(0, mes - 1).toLocaleString('default', { month: 'short' })}
            </button>
          );
        })}
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold">
          Total a pagar: <span className="text-blue-600">${total}</span>
        </h3>
      </div>

      <button
        onClick={realizarPago}
        className="bg-blue-500 text-white p-2 rounded"
      >
        Confirmar Pago
      </button>

      <div className="flex justify-center space-x-4 mb-4 mt-8">
        <button onClick={() => setAñoHistorial(añoHistorial - 1)} className="text-blue-500 hover:text-blue-700">◀</button>
        <h3 className="text-2xl font-semibold">Historial de pagos - {añoHistorial}</h3>
        <button onClick={() => setAñoHistorial(añoHistorial + 1)} className="text-blue-500 hover:text-blue-700">▶</button>
      </div>

      <div className="mt-6">
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-2 text-center">Mes(es) Pagado(s)</th>
                <th className="px-4 py-2 text-center">Año</th>
                <th className="px-4 py-2 text-center">Fecha de Pago</th>
                <th className="px-4 py-2 text-center">Monto Total</th>
                <th className="px-4 py-2 text-center">Factura</th>
              </tr>
            </thead>
            <tbody>
              {pagosFiltrados.length > 0 ? (
                pagosFiltrados.map((pago, index) => {
                  const mesInicio = new Date(0, pago.mes_inicio - 1).toLocaleString('default', { month: 'long' });
                  const mesFin = pago.mes_fin
                    ? new Date(0, pago.mes_fin - 1).toLocaleString('default', { month: 'long' })
                    : mesInicio;
                  const añoInicio = pago.año_inicio;
                  const añoFin = pago.año_fin || añoInicio;

                  return (
                    <tr key={index} className="border-b">
                      <td className="px-4 py-2 text-center">
                        {mesInicio === mesFin ? mesInicio : `${mesInicio} - ${mesFin}`}
                      </td>
                      <td className="px-4 py-2 text-center">
                        {añoInicio === añoFin ? añoInicio : `${añoInicio} - ${añoFin}`}
                      </td>
                      <td className="px-4 py-2 text-center">
                        {new Date(`${pago.fecha_pago}T00:00:00`).toLocaleDateString('es-MX', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
})}
                      </td>
                      <td className="px-4 py-2 text-center">
                        ${Number(pago.monto || 0).toFixed(2)}
                      </td>
                      <td className="px-4 py-2 text-center">
                        <button
                          onClick={() => viewFactura(pago.id_pago)}
                          className="bg-blue-500 text-white px-2 py-1 rounded"
                        >
                          Ver Factura
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="5" className="px-4 py-2 text-center">
                    No hay pagos registrados para este cliente en el año {añoHistorial}.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : (
    <p>Cargando cliente...</p>
  )}
</div>
  );
}

export default RegistrarPagoCliente;
