import React, { useState, useEffect, useRef } from 'react';
import { Doughnut, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { FaEllipsisV } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import apiURL from "../../api/api";

ChartJS.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const GraficasGastos = () => {
  const [gastosMensuales, setGastosMensuales] = useState({});
  const [gastosAnuales, setGastosAnuales] = useState([]);
  const [totalMensual, setTotalMensual] = useState(0);
  const [totalAnual, setTotalAnual] = useState(0);
  const [mes, setMes] = useState(new Date().getMonth() + 1);
  const [año, setAño] = useState(new Date().getFullYear());
  const [showMenuMensual, setShowMenuMensual] = useState(false);
  const [showMenuAnual, setShowMenuAnual] = useState(false);

  const doughnutRef = useRef(null);
  const barRef = useRef(null);

  // Colores para cada tipo de gasto
  const colores = {
    'Mantenimiento': '#00A5E0',
    'Otro': '#00C49A',
    'Pago Fontanero': '#FF8500',
    'Viático': '#E74C3C'
  };

  // Formateador de moneda en pesos mexicanos
  const formatoMoneda = (monto) => {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(monto);
  };

  // Función para obtener datos de gastos mensuales
  useEffect(() => {
    const fetchGastosMensuales = async () => {
      try {
        const response = await fetch(`${apiURL}/api/gastos/historial/mensual/${mes}/${año}`);
        const data = await response.json();

        // Inicializa los gastos mensuales por tipo
        const gastosPorTipo = { 'Mantenimiento': 0, 'Otro': 0, 'Pago Fontanero': 0, 'Viático': 0 };
        
        let total = 0;
        data.forEach(gasto => {
          const monto = Number(gasto.monto) || 0;
          gastosPorTipo[gasto.tipo_gasto] = monto;
          total += monto;
        });

        setGastosMensuales(gastosPorTipo);
        setTotalMensual(total);
      } catch (error) {
        
      }
    };

    // Función para obtener datos de gastos anuales
    const fetchGastosAnuales = async () => {
      try {
        const response = await fetch(`${apiURL}/api/gastos/anuales/${año}`);
        const data = await response.json();
        const total = data.reduce((acc, monto) => acc + (Number(monto) || 0), 0);
        setGastosAnuales(data);
        setTotalAnual(total);
      } catch (error) {
        
      }
    };

    fetchGastosMensuales();
    fetchGastosAnuales();
  }, [mes, año]);

  // Datos para la gráfica de Doughnut de gastos mensuales
  const gastosMensualesData = {
    labels: Object.keys(gastosMensuales),
    datasets: [
      {
        data: Object.values(gastosMensuales),
        backgroundColor: Object.keys(gastosMensuales).map(tipo => colores[tipo] || '#ccc'),
      },
    ],
  };

  // Datos para la gráfica de barras de gastos anuales
  const gastosAnualesData = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    datasets: [
      {
        label: 'Gastos ($)',
        data: gastosAnuales,
        backgroundColor: 'rgba(255, 99, 132, 0.8)',
      },
    ],
  };

  // Función para exportar datos como Excel
  const exportAsExcel = (data, sheetName, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  const handleExportMensualExcel = () => {
    const data = [
      ...Object.entries(gastosMensuales).map(([tipo, monto]) => ({
        Tipo: tipo,
        Monto: Math.round(monto) // Redondea el monto a entero
      })),
      { Tipo: 'Total', Monto: Math.round(totalMensual) } // Agrega la fila de total
    ];
    exportAsExcel(data, 'Gastos Mensuales', `Gastos_Mensuales_${mes}_${año}`);
  };

  const handleExportAnualExcel = () => {
    const data = [
      ...gastosAnuales.map((monto, index) => ({
        Mes: gastosAnualesData.labels[index],
        Monto: Math.round(monto) // Redondea el monto a entero
      })),
      { Mes: 'Total', Monto: Math.round(totalAnual) } // Agrega la fila de total
    ];
    exportAsExcel(data, 'Gastos Anuales', `Gastos_Anuales_${año}`);
  };


  // Exportar gráfica como PNG
  const exportChartAsPNG = (chartRef, fileName) => {
    const chart = chartRef.current;
    if (chart) {
      const url = chart.toBase64Image();
      const link = document.createElement('a');
      link.href = url;
      link.download = `${fileName}.png`;
      link.click();
    }
  };

  return (
    <div className="p-6 w-full">
      <h1 className="text-3xl font-bold mb-8 text-center">Gráficas de Gastos</h1>

      <div className="flex justify-center space-x-4 mb-6">
        <select value={mes} onChange={(e) => setMes(Number(e.target.value))} className="p-2 border rounded">
          {['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'].map((mesNombre, index) => (
            <option key={index + 1} value={index + 1}>{mesNombre}</option>
          ))}
        </select>
        <input 
          type="number"
          value={año}
          onChange={(e) => setAño(Number(e.target.value))}
          className="p-2 border rounded w-24"
          min="2000"
          max={new Date().getFullYear()}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Gráfica de Doughnut: Gastos del Mes */}
        <div className="bg-white p-6 shadow-lg rounded-lg relative">
          <h2 className="text-2xl font-semibold mb-2 text-center">Gastos del Mes</h2>
          <p className="text-xl font-bold mb-4 text-center">Total: {formatoMoneda(totalMensual)}</p>
          <FaEllipsisV 
            className="absolute top-4 right-4 cursor-pointer" 
            onClick={() => setShowMenuMensual(!showMenuMensual)} 
          />
          {showMenuMensual && (
            <div className="absolute top-10 right-4 bg-white shadow-lg rounded p-2 z-10">
              <button onClick={handleExportMensualExcel} className="block px-2 py-1 text-left">Descargar Excel</button>
              <button onClick={() => exportChartAsPNG(doughnutRef, `Gastos_Mensuales_${mes}_${año}`)} className="block px-2 py-1 text-left">Descargar PNG</button>
            </div>
          )}
          <div className="w-full h-[400px]">
            <Doughnut 
              ref={doughnutRef}
              data={gastosMensualesData} 
              options={{
                maintainAspectRatio: false,
                plugins: { legend: { position: 'top' } },
              }}
            />
          </div>
        </div>

        {/* Gráfica de Barras: Gastos Anuales */}
        <div className="bg-white p-6 shadow-lg rounded-lg relative">
          <h2 className="text-2xl font-semibold mb-2 text-center">Gastos Anuales</h2>
          <p className="text-xl font-bold mb-4 text-center">Total: {formatoMoneda(totalAnual)}</p>
          <FaEllipsisV 
            className="absolute top-4 right-4 cursor-pointer" 
            onClick={() => setShowMenuAnual(!showMenuAnual)} 
          />
          {showMenuAnual && (
            <div className="absolute top-10 right-4 bg-white shadow-lg rounded p-2 z-10">
              <button onClick={handleExportAnualExcel} className="block px-2 py-1 text-left">Descargar Excel</button>
              <button onClick={() => exportChartAsPNG(barRef, `Gastos_Anuales_${año}`)} className="block px-2 py-1 text-left">Descargar PNG</button>
            </div>
          )}
          <div className="w-full h-[400px]">
            <Bar 
              ref={barRef}
              data={gastosAnualesData} 
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  x: { ticks: { font: { size: 12 } } },
                  y: { ticks: { font: { size: 12 } } },
                },
                plugins: { legend: { position: 'top' } },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraficasGastos;
