import React, { useState, useRef } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import apiURL from "../../api/api";

function RegistrarGasto() {
  const [formData, setFormData] = useState({
    tipo_gasto: '',
    monto: '',
    fecha_gasto: '',
    descripcion: '',
    nombre_fontanero: '',
    nombre_agente: '',
    comprobante: null,
  });

  const [loading, setLoading] = useState(false);

  // Referencia para el campo de archivo
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'comprobante') {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleGenerateFactura = async () => {
    // Validar que todos los campos requeridos estén llenos
    if (
      !formData.nombre_fontanero ||
      !formData.nombre_agente ||
      !formData.monto ||
      !formData.fecha_gasto ||
      !formData.descripcion // Asegurarse de que la descripción está presente
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Campos incompletos',
        text: 'Por favor, rellena todos los campos antes de generar la factura, incluida la descripción.',
      });
      return; // No continuar si falta algún dato
    }
  
    try {
      const response = await axios.post(`${apiURL}/api/factura-temp`, {
        nombre_fontanero: formData.nombre_fontanero,
        nombre_agente: formData.nombre_agente,
        monto: formData.monto,
        fecha_gasto: formData.fecha_gasto,
        descripcion: formData.descripcion,
      }, {
        responseType: 'blob', // Para obtener el archivo PDF
      });
  
      // Descargar el archivo PDF generado
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `factura_${formData.nombre_fontanero}.pdf`);
      document.body.appendChild(link);
      link.click();
  
      Swal.fire({
        icon: 'success',
        title: 'Factura generada',
        text: 'La factura ha sido generada con éxito.',
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un problema al generar la factura.',
      });
     
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append('tipo_gasto', formData.tipo_gasto);
    formDataToSend.append('monto', formData.monto);
    formDataToSend.append('fecha_gasto', formData.fecha_gasto);
    formDataToSend.append('descripcion', formData.descripcion);

    if (formData.comprobante) {
      formDataToSend.append('comprobante', formData.comprobante);
    }

    try {
      await axios.post(`${apiURL}/api/gastos`, formDataToSend, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      Swal.fire({
        icon: 'success',
        title: 'Gasto registrado',
        text: 'El gasto ha sido registrado con éxito.',
      });

      // Limpiar los campos del formulario y el campo de archivo
      setFormData({
        tipo_gasto: '',
        monto: '',
        fecha_gasto: '',
        descripcion: '',
        nombre_fontanero: '',
        nombre_agente: '',
        comprobante: null,
      });

      // Reiniciar el campo de archivo
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un problema al registrar el gasto.',
      });
     
    }
    setLoading(false);
  };

  return (
    <div className="w-full p-6 flex min-h-screen bg-gray-100">
      {/* Sección del Formulario */}
      <div className="flex-grow">
        <h2 className="text-2xl font-bold mb-4 text-center w-full">Registrar Gasto</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="tipo_gasto" className="block text-sm font-medium text-gray-700">Tipo de Gasto</label>
            <select
              name="tipo_gasto"
              value={formData.tipo_gasto}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              required
            >
              <option value="" disabled>Selecciona el tipo de gasto</option>
              <option value="Pago Fontanero">Pago Fontanero</option>
              <option value="Mantenimiento">Mantenimiento</option>
              <option value="Viático">Viático</option>
              <option value="Otro">Otro</option>
            </select>
          </div>

          {formData.tipo_gasto === 'Pago Fontanero' && (
            <>
              <div>
                <label htmlFor="nombre_fontanero" className="block text-sm font-medium text-gray-700">Nombre del Fontanero</label>
                <input
                  type="text"
                  name="nombre_fontanero"
                  value={formData.nombre_fontanero}
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  required
                />
              </div>

              <div>
                <label htmlFor="nombre_agente" className="block text-sm font-medium text-gray-700">Nombre del Agente Municipal</label>
                <input
                  type="text"
                  name="nombre_agente"
                  value={formData.nombre_agente}
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  required
                />
              </div>
            </>
          )}

          <div>
            <label htmlFor="monto" className="block text-sm font-medium text-gray-700">Monto</label>
            <input
              type="number"
              name="monto"
              value={formData.monto}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          <div>
            <label htmlFor="fecha_gasto" className="block text-sm font-medium text-gray-700">Fecha de Gasto</label>
            <input
              type="date"
              name="fecha_gasto"
              value={formData.fecha_gasto}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          {formData.tipo_gasto === 'Pago Fontanero' && (
            <button
              type="button"
              onClick={handleGenerateFactura}
              className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded mt-4"
            >
              Generar Factura
            </button>
          )}

          <div>
            <label htmlFor="descripcion" className="block text-sm font-medium text-gray-700">Descripción</label>
            <textarea
              name="descripcion"
              value={formData.descripcion}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              rows="4"
              required
            ></textarea>
          </div>

          <div>
            <label htmlFor="comprobante" className="block text-sm font-medium text-gray-700">Subir Comprobante (opcional)</label>
            <input
              type="file"
              name="comprobante"
              onChange={handleChange}
              ref={fileInputRef} // Asignar referencia al input de archivo
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              accept="image/*,application/pdf"
            />
          </div>

          <div>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
              disabled={loading}
            >
              {loading ? 'Registrando...' : 'Registrar Gasto'}
            </button>
          </div>
        </form>
      </div>

      <div className="hidden lg:flex flex-shrink-0 ml-8">
        <img src="/img/Gasto1.png" alt="Gasto" className="w-90 h-85 object-contain" />
      </div>
    </div>
  );
}

export default RegistrarGasto;
