import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaArrowLeft, FaArrowRight, FaEdit } from 'react-icons/fa';
import { HiDownload } from 'react-icons/hi';
import Swal from 'sweetalert2';
import apiURL from "../../api/api";

function HistorialPagos() {
  const [pagos, setPagos] = useState([]);
  const [mensaje, setMensaje] = useState('');
  const [mes, setMes] = useState(new Date().getMonth() + 1);
  const [año, setAño] = useState(new Date().getFullYear());
  const [modo, setModo] = useState("mensual");
  const [pagina, setPagina] = useState(1);
  const [mostrarExportar, setMostrarExportar] = useState(false);
  const registrosPorPagina = 10;

  const meses = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];

  // Definir la función para obtener el historial de pagos
  const obtenerHistorialPagos = async () => {
    try {
      const response = modo === "mensual"
        ? await axios.get(`${apiURL}/api/pagos/historial/mensual/${mes}/${año}`)
        : await axios.get(`${apiURL}/api/pagos/historial/anual/${año}`);
      
      const pagosOrdenados = response.data.sort((a, b) => new Date(a.fecha_pago) - new Date(b.fecha_pago));
      setPagos(pagosOrdenados);
      setPagina(1);
    } catch (error) {
     
      setMensaje('Hubo un problema al cargar el historial de pagos.');
      Swal.fire('Error', 'Hubo un problema al cargar el historial de pagos.', 'error');
    }
  };

  useEffect(() => {
    obtenerHistorialPagos();
  }, [mes, año, modo]);

  const handleExport = async (formato) => {
    try {
      const response = await axios.get(
        `${apiURL}/api/pagos/exportar/${formato}/${modo}/${mes}/${año}`,
        { responseType: 'blob' }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `historial_pagos_${modo}_${mes}_${año}.${formato}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
     
    }
  };
  

  const formatearMeses = (mes_inicio, mes_fin) => {
    const mesInicioTexto = meses[mes_inicio - 1];
    const mesFinTexto = mes_fin && mes_inicio !== mes_fin ? meses[mes_fin - 1] : "";
    return mesFinTexto ? `${mesInicioTexto} - ${mesFinTexto}` : mesInicioTexto;
  };

  const formatearAños = (año_inicio, año_fin) => {
    return año_inicio === año_fin || !año_fin ? `${año_inicio}` : `${año_inicio} - ${año_fin}`;
  };

  const verFactura = async (pagoId) => {
    try {
      const response = await axios.get(`${apiURL}/api/factura/${pagoId}`, { responseType: 'blob' });
      const fileURL = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      window.open(fileURL);
    } catch (error) {
    
      Swal.fire('Error', 'Hubo un problema al cargar la factura.', 'error');
    }
  };

  const editarFechaPago = (pago) => {
    Swal.fire({
      title: 'Editar Fecha de Pago',
      html: `
        <div><strong>Cliente:</strong> ${pago.cliente.nombre} ${pago.cliente.apellidos}</div>
        <div><strong>Monto:</strong> $${Number(pago.monto).toFixed(2)}</div>
        <div><strong>Mes(es) Pagados:</strong> ${formatearMeses(pago.mes_inicio, pago.mes_fin)}</div>
        <div><strong>Año:</strong> ${formatearAños(pago.año_inicio, pago.año_fin)}</div>
        <div><strong>Fecha de Pago Actual:</strong> ${new Date(pago.fecha_pago).toLocaleDateString()}</div>
        <label for="fecha_pago">Nueva Fecha de Pago:</label>
        <input type="date" id="fecha_pago" class="swal2-input" required>
      `,
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      cancelButtonText: 'Cancelar',
      preConfirm: () => {
        const nuevaFecha = document.getElementById('fecha_pago').value;
        if (!nuevaFecha) {
          Swal.showValidationMessage('Por favor ingresa una fecha válida');
        }
        return nuevaFecha;
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const nuevaFecha = result.value;
          await axios.put(`${apiURL}/api/pagos/${pago.id_pago}/editar-fecha`, { fecha_pago: nuevaFecha });
          obtenerHistorialPagos();  // Actualizar la lista de pagos en tiempo real
          Swal.fire('Fecha actualizada', 'La fecha de pago ha sido actualizada exitosamente.', 'success');
        } catch (error) {
         
          Swal.fire('Error', 'No se pudo actualizar la fecha de pago.', 'error');
        }
      }
    });
  };

  const handleMesAnterior = () => {
    setMes(mes === 1 ? 12 : mes - 1);
    if (mes === 1) setAño(año - 1);
  };

  const handleMesSiguiente = () => {
    setMes(mes === 12 ? 1 : mes + 1);
    if (mes === 12) setAño(año + 1);
  };

  const handleAñoAnterior = () => setAño(año - 1);
  const handleAñoSiguiente = () => setAño(año + 1);
  const toggleModo = () => setModo(modo === "mensual" ? "anual" : "mensual");

  const indexOfLastPago = pagina * registrosPorPagina;
  const indexOfFirstPago = indexOfLastPago - registrosPorPagina;
  const pagosPaginaActual = pagos.slice(indexOfFirstPago, indexOfLastPago);

  const handlePaginaSiguiente = () => {
    if (pagina < Math.ceil(pagos.length / registrosPorPagina)) setPagina(pagina + 1);
  };
  const handlePaginaAnterior = () => {
    if (pagina > 1) setPagina(pagina - 1);
  };

  return (
    <div className="w-full p-4 md:p-6">
      <h2 className="text-2xl md:text-3xl font-bold mb-4 text-center">Historial de Pagos</h2>
      
      <div className="flex flex-col md:flex-row items-center justify-between mb-4 space-y-2 md:space-y-0">
        <div className="flex items-center justify-center space-x-2">
          {modo === "mensual" ? (
            <>
              <button onClick={handleMesAnterior} className="text-blue-500 hover:text-blue-700">
                <FaArrowLeft size={18} />
              </button>
              <span className="font-bold text-lg md:text-2xl mx-2">{meses[mes - 1]} {año}</span>
              <button onClick={handleMesSiguiente} className="text-blue-500 hover:text-blue-700">
                <FaArrowRight size={18} />
              </button>
            </>
          ) : (
            <>
              <button onClick={handleAñoAnterior} className="text-blue-500 hover:text-blue-700">
                <FaArrowLeft size={18} />
              </button>
              <span className="font-bold text-lg md:text-2xl mx-2">{año}</span>
              <button onClick={handleAñoSiguiente} className="text-blue-500 hover:text-blue-700">
                <FaArrowRight size={18} />
              </button>
            </>
          )}
        </div>

        <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-2">
          <button onClick={toggleModo} className="w-full md:w-auto px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 text-sm">
            Ver {modo === "mensual" ? "Anual" : "Mensual"}
          </button>

          <div className="relative w-full md:w-auto">
            <button onClick={() => setMostrarExportar(!mostrarExportar)} className="w-full md:w-auto px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600 flex items-center justify-center text-sm">
              <HiDownload size={18} className="mr-1" /> Exportar
            </button>
            {mostrarExportar && (
              <div className="absolute mt-2 right-0 bg-white shadow-lg rounded-lg z-10">
                <button onClick={() => handleExport('pdf')} className="block px-4 py-2 text-left hover:bg-gray-100">PDF</button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="overflow-x-auto w-full">
        <table className="min-w-full bg-white border border-gray-200 text-sm md:text-base">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-4 py-2 text-center">#</th>
              <th className="px-4 py-2">Nombre del Cliente</th>
              <th className="px-4 py-2 text-center">Monto</th>
              <th className="px-4 py-2 text-center">Fecha de Pago</th>
              <th className="px-4 py-2 text-center">Mes(es) Pagados</th>
              <th className="px-4 py-2 text-center">Año</th>
              <th className="px-4 py-2 text-center">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {pagosPaginaActual.length > 0 ? (
              pagosPaginaActual.map((pago, index) => (
                <tr key={pago.id_pago} className="border-b">
                  <td className="px-4 py-2 text-center">{indexOfFirstPago + index + 1}</td>
                  <td className="px-4 py-2">{`${pago.cliente.nombre} ${pago.cliente.apellidos}`}</td>
                  <td className="px-4 py-2 text-center">${Number(pago.monto).toFixed(2)}</td>
                  <td className="px-4 py-2 text-center">{new Date(`${pago.fecha_pago}T00:00:00`).toLocaleDateString('es-MX', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
})}</td>
                  <td className="px-4 py-2 text-center">{formatearMeses(pago.mes_inicio, pago.mes_fin)}</td>
                  <td className="px-4 py-2 text-center">{formatearAños(pago.año_inicio, pago.año_fin)}</td>
                  <td className="px-4 py-2 text-center">
                    <button onClick={() => verFactura(pago.id_pago)} className="bg-blue-500 text-white px-2 py-1 rounded mr-2">Ver Factura</button>
                    <button onClick={() => editarFechaPago(pago)} className="bg-yellow-500 text-white px-5 py-2 rounded"><FaEdit /></button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center py-4">{mensaje || 'No hay pagos registrados para este período.'}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="flex flex-col md:flex-row justify-between items-center mt-4 space-y-2 md:space-y-0">
        <button onClick={handlePaginaAnterior} disabled={pagina === 1} className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 disabled:opacity-50 text-sm w-full md:w-auto">
          Anterior
        </button>
        <button onClick={handlePaginaSiguiente} disabled={pagina === Math.ceil(pagos.length / registrosPorPagina)} className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 disabled:opacity-50 text-sm w-full md:w-auto">
          Siguiente
        </button>
      </div>
    </div>
  );
}

export default HistorialPagos;
