import React, { useState, useEffect } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import apiurl from '../../api/api';

const RegistrarPago = () => {
  const [clientes, setClientes] = useState([]);
  const [clientesFiltrados, setClientesFiltrados] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  // Obtener la lista de clientes y ordenarla al cargar el componente
  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const response = await fetch(`${apiurl}/api/clientes`);
        const data = await response.json();
        const clientesOrdenados = data.sort((a, b) =>
          a.nombre.localeCompare(b.nombre)
        );
        setClientes(clientesOrdenados);
        setClientesFiltrados(clientesOrdenados);
      } catch (error) {
       
      } finally {
        setIsLoading(false);
      }
    };

    fetchClientes();
  }, []);

  // Filtrar clientes mediante useMemo para evitar cálculos innecesarios
  useEffect(() => {
    const resultadosFiltrados = clientes.filter((cliente) =>
      `${cliente.nombre} ${cliente.apellidos}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setClientesFiltrados(resultadosFiltrados);
  }, [searchTerm, clientes]);

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-6">Registro de Pagos</h1>

      {/* Barra de búsqueda de clientes */}
      <div className="relative mb-4">
        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
        </span>
        <input
          type="text"
          placeholder="Buscar por nombre o apellidos"
          className="p-2 pl-10 border border-gray-300 rounded w-full"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Mostrar estado de carga */}
      {isLoading ? (
        <div className="flex justify-center items-center h-48">
          <div className="flex flex-col items-center">
            <div className="loader border-t-4 border-blue-500 rounded-full w-12 h-12 animate-spin"></div>
            <p className="text-gray-500 mt-2">Cargando clientes...</p>
          </div>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead className="bg-gray-200 uppercase">
              <tr>
                <th className="px-4 py-2 text-left">No</th>
                <th className="px-4 py-2 text-left">Nombre Completo</th>
                <th className="px-4 py-2 text-left">Referencia</th>
                <th className="px-2 py-2 text-left">Acción</th>
              </tr>
            </thead>
            <tbody className="uppercase">
              {clientesFiltrados.length > 0 ? (
                clientesFiltrados.map((cliente, index) => (
                  <tr key={cliente.id_cliente} className="border-b">
                    <td className="px-4 py-2">{index + 1}</td>
                    <td className="px-4 py-2">
                      {`${cliente.nombre.toUpperCase()} ${cliente.apellidos.toUpperCase()}`}
                    </td>
                    <td className="px-4 py-2">
                      {cliente.descripcion ? cliente.descripcion.toUpperCase() : ''}
                    </td>
                    <td className="px-2 py-2">
                      <Link
                        to={`/pagos/registrar/${cliente.id_cliente}`}
                        className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition-colors"
                      >
                        Pagar
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center py-4">
                    No hay clientes registrados.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default RegistrarPago;
