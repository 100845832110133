import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import apiURL from "../api/api";

function ListaClientesMora() {
  const [clientes, setClientes] = useState([]);
  const [filteredClientes, setFilteredClientes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [initialLoading, setInitialLoading] = useState(true);
  const [error, setError] = useState(null);
  const clientesPerPage = 50;

  const monthTranslations = {
    January: 'Enero',
    February: 'Febrero',
    March: 'Marzo',
    April: 'Abril',
    May: 'Mayo',
    June: 'Junio',
    July: 'Julio',
    August: 'Agosto',
    September: 'Septiembre',
    October: 'Octubre',
    November: 'Noviembre',
    December: 'Diciembre',
  };

  const fetchListaClientesMora = useCallback(async () => {
    if (initialLoading) setInitialLoading(true);
    setError(null);
    try {
      const response = await fetch(`${apiURL}/api/clientes/mora`);
      const data = await response.json();
  
      if (Array.isArray(data)) {
        const translatedData = data
          .map((cliente) => ({
            ...cliente,
            meses_pendientes: cliente.meses_pendientes
              .split(', ')
              .map((month) => monthTranslations[month] || month)
              .join(', '),
          }))
          .sort((a, b) => a.nombre_completo.localeCompare(b.nombre_completo)); // Ordenar por nombre completo
  
        setClientes(translatedData);
        setFilteredClientes(translatedData);
      } else {
        setClientes([]);
        setFilteredClientes([]);
      }
    } catch (error) {
      setError('Hubo un problema al cargar los clientes en mora.');
    } finally {
      setInitialLoading(false);
    }
  }, [initialLoading]);
  

  useEffect(() => {
    fetchListaClientesMora();
  }, [fetchListaClientesMora]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const filtered = clientes.filter((cliente) =>
      cliente.nombre_completo.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredClientes(filtered);
  };

  const indexOfLastCliente = currentPage * clientesPerPage;
  const indexOfFirstCliente = indexOfLastCliente - clientesPerPage;
  const currentClientes = filteredClientes.slice(indexOfFirstCliente, indexOfLastCliente);
  const totalPaginas = Math.ceil(filteredClientes.length / clientesPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPaginas) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(14);
    doc.text('Lista de usuarios con pagos pendientes', 65, 15);
  
    const tableData = filteredClientes.map((cliente, index) => [
      index + 1, // Número
      cliente.nombre_completo, // Nombre completo
      cliente.meses_pendientes, // Descripción meses pendientes
      cliente.total_meses, // Total de meses
    ]);
  
    doc.autoTable({
      head: [['#', 'Nombre completo', 'Meses pendientes', 'Total de meses']],
      body: tableData,
      startY: 20,
      styles: {
        lineColor: [0, 0, 0], // Color de las líneas (negro)
        lineWidth: 0.2, // Grosor más delgado de las líneas
        fontSize: 10, // Tamaño de fuente más pequeño para ajustar texto
      },
      headStyles: {
        fillColor: [0, 102, 204], // Fondo azul del encabezado
        textColor: [255, 255, 255], // Texto blanco en el encabezado
        halign: 'center', // Alineación centrada del encabezado
      },
      bodyStyles: {
        textColor: [0, 0, 0], // Texto negro en el cuerpo
        halign: 'left', // Alineación a la izquierda para las celdas
      },
      columnStyles: {
        0: { cellWidth: 15, halign: 'center' }, // Columna de números centrada
        1: { cellWidth: 60 }, // Ancho ajustado para "Nombre completo"
        2: { cellWidth: 75 }, // Ancho ajustado para "Meses pendientes"
        3: { cellWidth: 30, halign: 'center' }, // Centrado para "Total de meses"
      },
      margin: { top: 25 }, // Ajuste del margen superior
      theme: 'grid', // Estilo de tabla con líneas sutiles
    });
  
    doc.save('Pagos pendientes.pdf');
  };
  

  if (initialLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-solid mx-auto mb-4"></div>
          <p className="text-lg text-gray-700 font-semibold">Cargando datos...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <p className="text-red-500 text-lg font-semibold">{error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen p-4 bg-gray-100">
      <h1 className="text-3xl text-center font-bold mb-4 text-gray-800">Clientes en Mora</h1>

      <div className="flex justify-between items-center mb-4">
        <div className="flex">
          <input
            type="text"
            placeholder="Buscar cliente..."
            value={searchTerm}
            onChange={handleSearch}
            className="w-full max-w-md px-4 py-2 border border-gray-500 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          
        </div>
        <div className="flex">
          <button
            onClick={handleDownloadPDF}
            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition ml-2"
          >
            Descargar PDF
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table
          id="clientes-table"
          className="min-w-full bg-white shadow-md rounded-lg border border-gray-300"
        >
          <thead className="bg-blue-500 text-white">
            <tr>
              <th className="px-4 py-3 text-left">#</th>
              <th className="px-4 py-3 text-left">Nombre Completo</th>
              <th className="px-4 py-3 text-left">Meses Pendientes</th>
              <th className="px-4 py-3 text-left">Total de Meses</th>
              <th className="px-4 py-3 text-center">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {currentClientes.length > 0 ? (
              currentClientes.map((cliente, index) => (
                <tr
                  key={cliente.id_cliente}
                  className={`border-b ${
                    index % 2 === 0 ? 'bg-gray-50' : 'bg-white'
                  } hover:bg-blue-100`}
                >
                  <td className="px-4 py-3">{indexOfFirstCliente + index + 1}</td>
                  <td className="px-4 py-3">{cliente.nombre_completo}</td>
                  <td className="px-4 py-3">{cliente.meses_pendientes}</td>
                  <td className="px-4 text-center py-3">{cliente.total_meses}</td>
                  <td className="px-4 py-3 text-center">
                    <Link
                      to={`/pagos/registrar/${cliente.id_cliente}`}
                      className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600 transition-colors"
                    >
                      Pagar
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center py-4 text-gray-500">
                  No hay clientes en mora.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {totalPaginas > 1 && (
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded-lg ${
              currentPage === 1
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'bg-blue-500 text-white hover:bg-blue-600'
            }`}
          >
            Anterior
          </button>
          <span className="text-lg font-semibold">
            Página {currentPage} de {totalPaginas}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage >= totalPaginas}
            className={`px-4 py-2 rounded-lg ${
              currentPage >= totalPaginas
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'bg-blue-500 text-white hover:bg-blue-600'
            }`}
          >
            Siguiente
          </button>
        </div>
      )}
    </div>
  );
}

export default ListaClientesMora;
