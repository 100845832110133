// src/componentes/auth/LoginPersonal.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext'; // Ajuste de la ruta de importación
import apiURL from "../../api/api";

function LoginPersonal() {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth(); // Obtiene la función de login desde el contexto

  const [nombreUsuario, setNombreUsuario] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [logoUrl, setLogoUrl] = useState(''); // Almacena la URL del logo activo

  // Obtener el logo activo desde el servidor
  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await axios.get(`${apiURL}/api/logos/active`);
        setLogoUrl(response.data.path); // Guardar la URL del logo activo
      } catch (error) {
      
      }
    };
    fetchLogo();
  }, []);

  // Cambio de tipo de usuario
  const handleUserSwitch = (type) => {
    if (type === 'usuarios') {
      navigate('/login/usuarios');
    } else if (type === 'personal') {
      navigate('/login/personal');
    }
  };

  // Manejo del envío de formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!nombreUsuario || !password) {
        setErrorMessage("Todos los campos son obligatorios");
        return;
    }

    try {
      const response = await axios.post(`${apiURL}/api/login/personal`, {
            nombre_usuario: nombreUsuario,
            password: password,
        });

        if (response.status === 200) {
            login('personal', response.data.admin); // Asegúrate de pasar el dato correctamente
            setSuccessMessage("Inicio de sesión exitoso");
            setErrorMessage('');
            navigate('/dashboard'); // Redirigir al dashboard
        }
    } catch (error) {
        setSuccessMessage('');
        setErrorMessage(
            error.response?.status === 401
                ? "No hay un usuario con estas credenciales. Verifica tus datos e intenta de nuevo."
                : "Error en el servidor. Inténtalo más tarde."
        );
    }
};


  // Limpia mensajes después de 5 segundos
  useEffect(() => {
    if (errorMessage || successMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
        setSuccessMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage, successMessage]);

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="relative w-full max-w-6xl mx-auto">
        <div className="flex flex-col lg:flex-row bg-white shadow-lg rounded-lg overflow-hidden w-full">
          
          {/* Sección con la imagen del lado izquierdo */}
          <div className="lg:w-2/5 hidden lg:flex bg-purple-500">
            <img 
              src="/img/agua-en-el-futuro.jpg" 
              alt="Agua en el Futuro"
              className="h-full w-full object-cover"
            />
          </div>

          {/* Sección del formulario */}
          <div className="w-full lg:w-3/5 p-8 lg:p-12 flex flex-col justify-center">
            <div className="text-center">
              <img 
                src={`https://apiserviceaguaboquilla.serviaguaboquilla.com/storage/logos/${logoUrl}`} 
                alt="Logo" 
                className="mx-auto h-28 w-30"
              />
              <h2 className="mt-6 text-3xl font-bold text-gray-900">
                Servicio de Agua Potable
              </h2>
            </div>

            {/* Botones para cambiar entre Usuarios y Personal */}
            <div className="mt-4 flex justify-center space-x-2">
              <button 
                className={`px-4 py-2 rounded-full ${location.pathname === '/login/usuarios' ? 'bg-red-600 text-white' : 'bg-gray-300'}`}
                onClick={() => handleUserSwitch('usuarios')}
              >
                Usuarios
              </button>
              <button 
                className={`px-4 py-2 rounded-full ${location.pathname === '/login/personal' ? 'bg-red-600 text-white' : 'bg-gray-300'}`}
                onClick={() => handleUserSwitch('personal')}
              >
                Personal
              </button>
            </div>

            {/* Formulario de login */}
            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="nombre_usuario" className="block text-sm font-medium text-gray-700">
                  Nombre de Usuario
                </label>
                <input
                  type="text"
                  name="nombre_usuario"
                  id="nombre_usuario"
                  placeholder="Nombre de usuario"
                  value={nombreUsuario}
                  onChange={(e) => setNombreUsuario(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md mt-1"
                  autoComplete="current-nombre_usuario"
                />
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Contraseña
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md mt-1"
                  autoComplete="current-password"
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full bg-red-600 text-white py-2 rounded-md hover:bg-red-700 transition"
                >
                  Iniciar sesión
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Mostrar mensajes de error o éxito */}
        {errorMessage && (
          <div className="fixed top-5 right-5 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded shadow-lg animate-slide-in" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        )}

        {successMessage && (
          <div className="fixed top-5 right-5 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded shadow-lg animate-slide-in" role="alert">
            <strong className="font-bold">Éxito: </strong>
            <span className="block sm:inline">{successMessage}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default LoginPersonal;
