import React, { useEffect, useState, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import apiURL from "../../api/api";

const HistorialPagoC = () => {
  const { clienteInfo } = useAuth();
  const [estadoPagos, setEstadoPagos] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [error, setError] = useState(null);

  const meses = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo',
    'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre',
    'Noviembre', 'Diciembre'
  ];

  const obtenerHistorialPagos = useCallback(async () => {
    if (!clienteInfo || !clienteInfo.id_cliente) return;

    setCargando(true);
    setError(null);

    try {
      const response = await axios.get(
        `${apiURL}/api/pagos/${clienteInfo.id_cliente}`
      );

      if (response.data && response.data.pagos) {
        const pagosFiltrados = response.data.pagos.filter((pago) => {
          // Excluir pagos con monto 0 o estado "pendiente"
          if (pago.monto === 0 || pago.estado_pago === "pendiente") {
            return false;
          }

          // Validar fecha de pago
          const fechaPagoValida = new Date(pago.fecha_pago);
          if (isNaN(fechaPagoValida.getTime())) {
            return false;
          }

          return true; // Pago válido
        });

        const pagosOrdenados = pagosFiltrados.sort((a, b) => {
          const fechaA = new Date(a.año_inicio, a.mes_inicio - 1);
          const fechaB = new Date(b.año_inicio, b.mes_inicio - 1);
          return fechaA - fechaB;
        });

        setEstadoPagos(pagosOrdenados.slice(0, 10)); // Tomar los 10 pagos más recientes
      } else {
        setEstadoPagos([]);
      }
    } catch (err) {
      console.error('Error al obtener el historial de pagos:', err);
      setError('No se pudieron cargar los datos. Intenta nuevamente.');
    } finally {
      setCargando(false);
    }
  }, [clienteInfo]);

  useEffect(() => {
    obtenerHistorialPagos();
  }, [obtenerHistorialPagos]);

  const downloadFactura = (id_pago) => {
    window.open(`${apiURL}/api/factura/${id_pago}`, '_blank');
  };

  if (cargando) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-solid mx-auto mb-4"></div>
          <p className="text-lg text-gray-700 font-semibold">Cargando datos...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-red-500 text-lg font-semibold">{error}</p>
      </div>
    );
  }

  return (
    <div className="p-4 sm:p-8">
      <h1 className="text-lg sm:text-2xl text-center font-bold">Historial de Pagos</h1>
      <p className="mt-4 text-sm sm:text-base text-center">
        Consulta los 10 pagos más recientes registrados en el sistema.
      </p>

      <div className="overflow-x-auto mt-6">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-4 py-2 border text-sm sm:text-base">Mes(es) Pagado(s)</th>
              <th className="px-4 py-2 border text-sm sm:text-base">Año</th>
              <th className="px-4 py-2 border text-sm sm:text-base">Fecha de Pago</th>
              <th className="px-4 py-2 border text-sm sm:text-base">Monto Total</th>
              <th className="px-4 py-2 border text-sm sm:text-base">Factura</th>
            </tr>
          </thead>
          <tbody>
            {estadoPagos.length > 0 ? (
              estadoPagos.map((pago, index) => {
                const mesInicio = meses[pago.mes_inicio - 1];
                const mesFin = pago.mes_fin ? meses[pago.mes_fin - 1] : mesInicio;
                const añoInicio = pago.año_inicio;
                const añoFin = pago.año_fin || añoInicio;

                return (
                  <tr key={index} className="border-b">
                    <td className="px-4 py-2 text-center text-sm sm:text-base">
                      {mesInicio === mesFin
                        ? mesInicio
                        : `${mesInicio} - ${mesFin}`}
                    </td>
                    <td className="px-4 py-2 text-center text-sm sm:text-base">
                      {añoInicio === añoFin ? añoInicio : `${añoInicio} - ${añoFin}`}
                    </td>
                    <td className="px-4 py-2 text-center text-sm sm:text-base">
                      {new Date(`${pago.fecha_pago}T00:00:00`).toLocaleDateString('es-MX', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
})}
                    </td>
                    <td className="px-4 py-2 text-center text-sm sm:text-base">
                      ${Number(pago.monto || 0).toFixed(2)}
                    </td>
                    <td className="px-4 py-2 text-center text-sm sm:text-base">
                      <button
                        onClick={() => downloadFactura(pago.id_pago)}
                        className="bg-blue-500 text-white px-2 py-1 rounded"
                      >
                        Ver Factura
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="5" className="px-4 py-2 text-center text-gray-500">
                  No hay pagos registrados para este cliente.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HistorialPagoC;
